import ReactDOM from "react-dom";
import {host} from '../App.config.json';

export function getFirstName (name: string) {
    return name.indexOf(" ") < 10? name.substring(0, name.indexOf(" ")) : name.substring(0, 10);
  }

export function swipeSlider (src: any, slider : any) {
  let snode = ReactDOM.findDOMNode(slider);
  if (src.clientX > document.body.clientWidth / 2) {
    (snode as HTMLIonSlidesElement).slideNext();
  } else {
    (snode as HTMLIonSlidesElement).slidePrev();
  }
}

export function setUnreadMessages (unread: boolean) {
  for ( let i = 0; i < (window as any).unreadMsgsListeners.length; i++) {
    (window as any).unreadMsgsListeners[i](unread);
  }
}

export function newMessage (msg: any) {
  for ( let i = 0; i < (window as any).newMsgsListeners.length; i++) {
    (window as any).newMsgsListeners[i](msg);
  }
}

export function newMatch () {
  for ( let i = 0; i < (window as any).newMatchListeners.length; i++) {
    (window as any).newMatchListeners[i]();
  }
}

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];
export function getMonthsNames () {
  return monthNames;
}

const weekdays = new Array(7);
weekdays[0] = "SUN";
weekdays[1] = "MON";
weekdays[2] = "TUE";
weekdays[3] = "WED";
weekdays[4] = "THU";
weekdays[5] = "FRI";
weekdays[6] = "SAT";
export function getWeekdays () {
  return weekdays;
}

export function getEventDateStr (eventDate : string, short?: boolean, past?: boolean) {
  let _eventDate = new Date(eventDate);
  if (past && _eventDate < new Date()) return "-past-";
  let monthName = monthNames[_eventDate.getMonth()];
  if (short && monthName) monthName = monthName.substring(0,3);
  return (short? "" : weekdays[_eventDate.getDay()] + ", ") + _eventDate.getDate() + " " + monthName + " " + + _eventDate.getFullYear();
}

export function loglevelServerSend (logger : any, options : any) {
  return; // disable logsave for now
  if (!logger || !logger.methodFactory)
      throw new Error('loglevel instance has to be specified in order to be extended')
  
  var _logger          = logger, 
      _url             = options && options.url || host + '/logsave',
      _callOriginal    = options && options.callOriginal || true,
      // _prefix          = options && options.prefix,
      _prefix = function(logSev : any, message : string) {
        let prefixUser = options.user? options.user.id + " (" + options.user.name + ') ' : '<no user> ';
        return prefixUser + '[' + new Date().toISOString() + '] ' + logSev + ': ' + message + '\n'   
      },
      _originalFactory = _logger.methodFactory,
      _sendQueue : any[]       = [],
      _isSending       = false
  
  _logger.methodFactory = function (methodName : string, logLevel : string) {
      var rawMethod = _originalFactory(methodName, logLevel)
  
      return function (message : string) {
          if (typeof _prefix === 'string')
              message = _prefix + message
          else if (typeof _prefix === 'function')
              message = _prefix(methodName,message)
          else
              message = methodName + ': ' + message
                      
          if (_callOriginal) 
              rawMethod(message)
          
          _sendQueue.push(message)
          _sendNextMessage()
      }
  }
  // _logger.setLevel(_logger.levels.WARN)
  _logger.enableAll();
  
  var _sendNextMessage = function(){
      if (!_sendQueue.length || _isSending) {
        console.log("not sending");
        return;
      }
      
      _isSending = true
      
      var msg = _sendQueue.shift(),
          req = new XMLHttpRequest()
      
      req.open("POST", _url, true)
      req.setRequestHeader('Content-Type', 'text/plain')
      req.onreadystatechange = function() {
          if(req.readyState == 4) 
          {
              _isSending = false
              setTimeout(_sendNextMessage, 0)
          }
      }
      req.send(msg)
  }
}