import { IonContent, IonPage, IonGrid, IonRow, IonCol, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';

import EditEventContainer from '../components/EditEventContainer';

const EditEvent: React.FC = () => {
  
  const [wideScreenMode, setWideScreenMode] = useState(window.innerWidth > 800);

  return (
    <IonPage>
      {/* <IonHeader>
        <IonToolbar>
            <IonButton href="/Profile" disabled>
              <IonIcon className="topicon" src="assets/icon/profile-on.svg" />
            </IonButton>
            <IonButton disabled>
            <IonIcon className="topicon" src="assets/icon/events.svg" />
            </IonButton>
            <IonButton href="/Home" disabled>
              <IonIcon slot="icon-only" src="assets/icon/swipe.svg" />
            </IonButton>
            <IonButton disabled>
              <IonIcon slot="icon-only" src="assets/icon/chat.svg" />
            </IonButton>
        </IonToolbar>
    </IonHeader> */}
      <IonContent>
      {wideScreenMode? (
        <IonGrid>
          <IonRow>
          <IonCol className="sideCol" size="1"></IonCol>
            <IonCol className="mainCol" size="10">
              <div className="mainDiv">
              <EditEventContainer/>
              </div>
            </IonCol>
             <IonCol className="sideCol" size="1"></IonCol>
          </IonRow>
        </IonGrid>
                ):(
                  <div>
                  <EditEventContainer/>
                </div>
                )}
      </IonContent>
    </IonPage>
  );
};

export default EditEvent;
