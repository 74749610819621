import React, { useState }  from 'react';
import './MPContainer.css';
import {host} from '../App.config.json';
import { Plugins } from '@capacitor/core';
import { IonButton, useIonViewWillEnter } from '@ionic/react';
import {isMobile} from 'react-device-detect';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import { useHistory } from 'react-router';

interface ContainerProps { }

type Props = {
  type: String,
  id: String,
  callback?: any,
  confirmReport?: any,
  text?: String
}


const ReportContainer: React.FC<Props> = ({type, id, callback = null, confirmReport = null, text = null}) => {

  const history = useHistory();
  const { Storage } = Plugins;

  useIonViewWillEnter(() => {
    loglevelServerSend(log,{});
  });

    
  const reportContent = async (res: any) => {
    
    if (confirmReport) {
      let confirmReportRes = confirmReport();
      if (!confirmReportRes) return;
      // This can be used to confirm reporting and blocking organizer/guest
    }
    var reason = prompt("Report reason (optional):");
    if (reason == null) return;
    
    let userDetailsStr = await Storage.get({key:"userDetails"});
    let userDetails = JSON.parse(userDetailsStr.value + "");

    fetch(host + "/reportContent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, type: type, id: id, reason: reason})})
        .then(res => res.json())
        .then(
          (reportResul) => {
            if (reportResul.status == "fail") {
              alert("Content report failed, please send report via email or chat message to out support team.");
            } else if (reportResul.status == "success") {
              alert("Content has been reported");
              if (callback) {
                callback();
              } else {
                history.push("/Events");
              }
            }
          
          },
          (error) => {
            log.error("error reportContent: " + error);
          }
        );
      }

      return (
          <div className="reportLink" onClick={reportContent}>{text? text : "Report"}</div>
      )
}

export default ReportContainer;