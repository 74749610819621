import { IonContent, IonHeader, IonPage, IonToolbar, IonButton, IonIcon, IonGrid, IonRow, IonCol, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
//import ExploreContainer from '../components/ExploreContainer';
import HelloContainer from '../components/HelloContainer';
import ExitContainer from '../components/ExitContainer';
import WebFooterContainer from '../components/WebFooter';
import {isWebApp as isWebApp} from '../App.config.json';
import {isIOS, isAndroid, isMobileSafari, isChrome, isChromium, isEdge, isEdgeChromium, isFirefox, isOpera, isSafari, isBrowser} from 'react-device-detect';
import MobileWebFooterContainer from '../components/MobileWebFooter';

const Hello: React.FC = () => {
  
  const [wideScreenMode, setWideScreenMode] = useState(window.innerWidth > 800);

  const [unreadMessages, setUnreadMessages] = useState(false);
  const unreadMsgs = (unread: boolean) => {
    setUnreadMessages(unread);
  }

  useIonViewWillEnter(() => {
    
    (window as any).unreadMsgsListeners.push(unreadMsgs);

  });

  return (
    <IonPage>

    <IonContent className="mycontentxxx">
    {wideScreenMode? (
      <div>
        <IonGrid className="mainContainerForFooter">
          <IonRow>
          <IonCol className="sideCol" size="1"></IonCol>
            <IonCol className="mainCol" size="10" style={{textAlign: "center"}}>
              <div className={wideScreenMode? "mainDivWide" : "mainDiv"}>
                <HelloContainer/>
                <ExitContainer></ExitContainer>
              </div>
            </IonCol>
            <IonCol className="sideCol" size="1"></IonCol>
          </IonRow>
        </IonGrid>
        
        {isWebApp == "true"? (
        <WebFooterContainer></WebFooterContainer>
        ): ""}
    
        </div>
    ):(
        <div>
          <div style={{minHeight: "74vh", paddingTop: "20px"}}>
          <HelloContainer/>
          <ExitContainer></ExitContainer>
          </div>
          {((isMobileSafari || isChrome || isChromium || isEdge || isEdgeChromium || isFirefox || isOpera || isSafari || isBrowser) && (isIOS || isAndroid))? (
          <MobileWebFooterContainer></MobileWebFooterContainer>
          ):""}

        </div>
    )}
      </IonContent>
    </IonPage>
  );
};

export default Hello;
