import { IonContent, IonHeader, IonPage, IonToolbar, IonButton, IonIcon, IonGrid, IonRow, IonCol, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
//import ExploreContainer from '../components/ExploreContainer';
import ChatContainer from '../components/ChatContainer';
import ExitContainer from '../components/ExitContainer';

const Chats: React.FC = () => {

  const [wideScreenMode, setWideScreenMode] = useState(window.innerWidth > 800);
  
  useIonViewWillEnter(() => {
    
  });

  return (
    <IonPage>
      {/* <IonHeader className="myheader">
        <IonToolbar>
            <Link to="/Profile">
              <IonIcon className="topicon" src="assets/icon/profile.svg" />
            </Link>
            <Link to="/Events">
            <IonIcon className="topicon" src="assets/icon/events.svg" />
            </Link>
            <Link to="/Swipe">
              <IonIcon slot="icon-only" src="assets/icon/swipe.svg" />
            </Link>
            <Link to="/Chats">
              <IonIcon slot="icon-only" src="assets/icon/chat-on.svg" />
            </Link>
        </IonToolbar>
    </IonHeader> */}

    <IonContent>
    {wideScreenMode? (
        <IonGrid>
          <IonRow>
          <IonCol className="sideCol" size="1"></IonCol>
            <IonCol className="mainCol" size="10">
              <div className="mainDiv">
                <ChatContainer/>
                <ExitContainer></ExitContainer>
              </div>
            </IonCol>
             <IonCol className="sideCol" size="1"></IonCol>
          </IonRow>
        </IonGrid>
                ):(
                  <div>
                  <ChatContainer/>
                  <ExitContainer></ExitContainer>
                </div>
                )}
        
      </IonContent>
    </IonPage>
  );
};

export default Chats;
