import React, { useState }  from 'react';
import { IonButton, IonTextarea, IonItem, IonHeader, IonToolbar, IonIcon, IonDatetime, IonRange, IonPopover, IonTitle, useIonViewWillEnter, IonModal, IonContent } from '@ionic/react';
import { IonInput, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useForm } from "react-hook-form";
import { Plugins } from '@capacitor/core';
import {host, versionName} from '../App.config.json';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
// import Cropper from 'react-cropper';
// import 'cropperjs/dist/cropper.css'
// import FacebookLogin from '@greatsumini/react-facebook-login';
import PicturesEditContainer from './PicturesEditContainer';
import {isMobile} from 'react-device-detect';
import log from 'loglevel';
import {isIOS} from 'react-device-detect';
import {loglevelServerSend} from './utils';
// import 'react-image-crop/dist/ReactCrop.css';
import '../theme/ReactCrop.css';


import './MPContainer.css';
import { useHistory } from 'react-router';

interface ContainerProps { }

// const cropper = React.createRef();
// var Cropper = require('react-cropper').default;

let userDetails : any;
let rangeValueInit = { lower: 21, upper: 29 };
// const maxPhotos = 7;
const FACEBOOK_PERMISSIONS = ['public_profile', 'email', 'user_photos'];

const SetupContainer: React.FC<ContainerProps> = () => {

  const history = useHistory();

  const [emailNotConfirmed, setEmailNotConfirmed] = useState(false);

  defineCustomElements(window);
  const [userName, setUserName] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userSex, setUserSex] = useState("");
  const [userSexMore, setUserSexMore] = useState("");
  const [userDateOfBirth, setUserDateOfBirth] = useState("1990-01-01");
  const [userAbout, setUserAbout] = useState("");
  const [userIIGender, setUserIIGender] = useState("");
  const [ageRange, setAgeRange] = useState("21-29");
  const [, setRangeValue] = useState<{lower: number; upper: number;}>({ lower: 21, upper: 29 });
  // const [pictures, setPictures] = useState([]);
  let maxDOB = new Date();
  maxDOB.setFullYear(maxDOB.getFullYear() - 18);
  let minDOB = new Date();
  minDOB.setFullYear(minDOB.getFullYear() - 95);

  function handle_setUserDateOfBirth (val:any) {
    if (!val || val == "undefined") {
      return;
      // val = maxDOB.getFullYear() + "-" + maxDOB.getMonth() + "-" + maxDOB.getDate();
    }
    setUserDateOfBirth(val);
    Storage.set({key: "userDateOfBirth", value: val});
  } 
  function handle_setUserSex (val:any) {
    setUserSex(val);
    Storage.set({key: "userSex", value: val});
  } 
  function handle_setUserSexMore (val:any) {
    setUserSexMore(val);
    Storage.set({key: "userSexMore", value: val});
  } 
  function handle_userIIGender (val:any) {
    setUserIIGender(val);
    Storage.set({key: "userIIGender", value: val});
  } 
  const setIIAges = (range : any) => {
    Storage.set({key: "userIIAges", value: JSON.stringify(range)});
    if (rangeValueInit.lower == range.lower && rangeValueInit.upper == range.upper) return;
    rangeValueInit = range;
    setRangeValue(range);
    setAgeRange(range.lower + "-" + ((range.upper == 55)? "55+": range.upper));
  }

  const { Storage } = Plugins;

  const [wideScreenMode, setWideScreenMode] = useState(false);

  const [isWeb, setIsWeb] = useState(false);

  useIonViewWillEnter(() => {
    
    window.scrollTo({top: 0, behavior: "smooth"});
  
    if (window.innerWidth > 800) {
      setWideScreenMode(true);
    }

    Storage.get({key:"userDetails"}).then((data: any) => {
      if (data.value) {
        userDetails = JSON.parse(data.value);
        loglevelServerSend(log,{user: userDetails});

        setUserName(userDetails.name);
        setUserCity(userDetails.city);
        handle_setUserDateOfBirth(userDetails.dob);
        if (userDetails.sex && ['male','female'].indexOf(userDetails.sex) == -1) {
          handle_setUserSex('more');
          handle_setUserSexMore(userDetails.sex);
        } else {
          handle_setUserSex(userDetails.sex);
        }
        setUserAbout(userDetails.about);
        handle_userIIGender(userDetails.iiGender);
        if (!userDetails.iiMinAge || !userDetails.iiMaxAge) {
          // first setup, still no value
          setIIAges({lower: 21, upper: 29});
        } else {
          setIIAges({lower: userDetails.iiMinAge, upper: userDetails.iiMaxAge});
        }
        if (!userDetails.emailConfirmed) setEmailNotConfirmed(true);
        setEmail(userDetails.email);
        setPassword(userDetails.password);
        setPasswordRetype(userDetails.password);
        // if (userDetails.login && !userDetails.login.fbUserId) {
        //   setConnectFacebook(true);
        // }
      }
    });
  
  });
  

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data : any) => {
    
    if (data.name.length < 3) {
      alert("Name is required.");
      (document.getElementsByName("name").item(0) as HTMLIonInputElement).setFocus();
      return;
    }

    let dob = await Storage.get({key:'userDateOfBirth'});
    if (!dob.value || dob.value == "undefined") {
      alert("Date of birth is required.");
      return;
    } else if (new Date(dob.value + "") > maxDOB) {
      alert("Minimum age must be 18");
      return;
    } else if (new Date(dob.value + "") < minDOB) {
      alert("Please check your date of birth...");
      return;
    }
    let sex = await Storage.get({key:'userSex'});

    if ('more' == sex.value) {
      sex = await Storage.get({key:'userSexMore'});
    }
    if (!sex.value || sex.value == "undefined") {
      alert("Gender is required.");
      return;
    }
    if (!sex.value.match(/^[A-Za-z ]+$/)) {
      alert('Only letters are allowed.');
      return;
    }
    let iiSex = await Storage.get({key:'userIIGender'});
    if (!iiSex.value || iiSex.value == "undefined") {
      alert("Show Me (Gender) is required.");
      return;
    }
    let userIIAgesStr = await Storage.get({key:'userIIAges'});
    let userIIAges = JSON.parse(userIIAgesStr.value + "");
    
    // if (data.about.length < 1) {
    //   alert("We don't believe in an blank profiles. Write something about yourself.");
    //   (document.getElementsByName("about").item(0) as HTMLIonTextareaElement).setFocus();
    //   return;
    // }

    let storageData = await Storage.get({key:"userDetails"});
    
    if (storageData.value) {
      userDetails = JSON.parse(storageData.value);
      userDetails.name = data.name;
      userDetails.city = data.city;
      userDetails.dob = dob.value;
      userDetails.sex = sex.value;
      userDetails.about = data.about;
      userDetails.iiGender = iiSex.value;
      userDetails.iiMinAge = userIIAges.lower;
      userDetails.iiMaxAge = userIIAges.upper;
      // userDetails.pictures = data.pictures;
      const userDetailsStringified = JSON.stringify(userDetails);
      Storage.set({key:"userDetails", value: userDetailsStringified});
      
      fetch(host + "/setup", {
        method: 'POST', headers: {
          'Content-Type': 'application/json'}, body: userDetailsStringified})
          .then(res => res.json())
          .then(
            (result) => {
              if (result.status == "success") {
                showStatus("Update successful.");
                history.push("/Profile");
              } else {
              showStatus("Update failed.");
              }
            },
            (error) => {
              log.error("error setup: " + error);
              showStatus("Update failed.");
            }
          );
          // -fetch
    }
  

    

  }; // onSubmit
  
  function showStatus (txt : string) {
    var statusLabel = document.getElementById("statusLabel");
    if (statusLabel) {
      statusLabel.classList.remove("displayNone");
      statusLabel.innerText = txt;
    }
  }


  const resendEmailConfirmation = () => {
    fetch(host + "/resendEmailConfirmation", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
              setEmailNotConfirmed(false);
            }
          },
          (error) => {
            log.error("error resendEmailConfirmation: " + error);
          }
        );
        // -fetch
  }

  const [showEmailPasswordPopover, setShowEmailPasswordPopover] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRetype, setPasswordRetype] = useState("");

  const emailPasswordChange = () => {
    
    if (!email.match(/^[a-zA-Z0-9_\.+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+$/)) {
      alert("Email not valid.");
      return;
    }
    if (password.length < 7) {
      alert("Password must be at least 7 characters long");
      return;
    }
    if (password != passwordRetype) {
      alert("Password retype doesn't match");
      return;
    }
    userDetails.email = email;
    userDetails.password = password;
    const userDetailsStringified = JSON.stringify(userDetails);
    
    fetch(host + "/setup", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: userDetailsStringified})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
              const userDetailsStringified = JSON.stringify(result.userDetails);
              Storage.set({key:"userDetails", value: userDetailsStringified});
              showStatus("Account updated.");
              setShowEmailPasswordPopover(false);
            } else {
            alert("Update failed" + (result.reason? (" - " + result.reason) : ""));
            }
          },
          (error) => {
            log.info("error emailPasswordChange: " + error);
            alert("Update failed.");
          }
        );
        // -fetch
  }

  const [connectFacebook, setConnectFacebook] = useState(false);

  const connectWithFacebook = async () => {
    /*
    try {
      let result = await Plugins.FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });//.then((result: any)  => { // FacebookLoginResponse
        if (result.accessToken) {
          // Login successful.
          log.info('Facebook access token is ${result.accessToken.token} and userId is ${result.accessToken.userId}');
          // alert("sign in ok, token: " + result.accessToken.token + ", userId: " + result.accessToken.userId);
          
          // register
          // fetch("https://graph.facebook.com/v7.0/" + result.accessToken.userId + "/?access_token=" + result.accessToken.token, {
          //   method: 'GET', headers: {
          //     'Content-Type': 'application/json'}})
          //     .then(res => res.json())
          //     .then(
          //       () => {
                  connectWithFacebook2(result.accessToken.token, result.accessToken.userId);
                // },
                // (error) => {
                //   log.error("error connectWithFacebook: " + error);
                // }); //-fetch

        } else {
          // Cancelled by user.
          log.error("Error signing in with Facebook (Cancelled by user - in SetupContainer)");
          alert("Error signing in with Facebook")
        }
      // });
    } catch (ex) {
      setIsWeb(true);
    }
*/
  }
  const responseFacebook = (response : any) => {
    connectWithFacebook2(response.accessToken, response.id);
  }
  const connectWithFacebook2 = (token: string, userId: string) => {
    fetch(host + "/connectFacebook", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, fbUserId: userId, fbToken: token})})
        .then(res => res.json())
        .then(
          (result) => {
            if ("success" == result.status) {
              Storage.set({key:"userDetails", value: JSON.stringify(result.userDetails)});
              userDetails = result.userDetails;
              setConnectFacebook(false);
            }
          },
          (error) => {
            log.error("error connectWithFacebook2: " + error);
            alert(error);
          }
        );
  }

  const savePhotos = (photos: []) => {
    userDetails.pictures = photos;
    const userDetailsStringified = JSON.stringify(userDetails);
    Storage.set({key:"userDetails", value: userDetailsStringified});
    
    // var debugme = "";
    // for (let i = 0; i < photos.length; i++) {
    //   if ((photos[i] + "").indexOf("zB") == 0) debugme += "Cup\n";
    //   else if ((photos[i] + "").indexOf("vV") == 0) debugme += "Selfie\n";
    //   else if ((photos[i] + "").indexOf("crw") == 0) debugme += "Lamps\n";
    //   else if ((photos[i] + "").indexOf("332") == 0) debugme += "Shirt\n";
    //   else if ((photos[i] + "").indexOf("JZ") == 0) debugme += "Bunny\n";
    // }
    // alert(debugme);

    fetch(host + "/setup", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: userDetailsStringified})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
            //   showStatus("Update successful.");
              // document.location.href = "/Profile";
            } else {
            // showStatus("Update failed.");
            }
          },
          (error) => {
            log.error("error savePhotos: " + error);
            // showStatus("Update failed.");
          }
        );
        // -fetch
  }

  const getUserDetails = () => {
    return userDetails;
  }
  
  return (
    <div className={"mpcontainer maincontainer" + (isIOS? " " : "")}>
    <form onSubmit={handleSubmit(onSubmit)} id="mainform">
     <IonHeader className={wideScreenMode? "myheadeWide":"myheader"}>
        <IonToolbar>
            {/* <IonButton href="/Profile" disabled>
              <IonIcon className="topicon" src="assets/icon/profile-on.svg" />
            </IonButton>
            <IonButton disabled>
            <IonIcon className="topicon" src="assets/icon/events.svg" />
            </IonButton>
            <IonButton href="/Home" disabled>
              <IonIcon slot="icon-only" src="assets/icon/swipe.svg" />
            </IonButton>
            <IonButton disabled>
              <IonIcon slot="icon-only" src="assets/icon/chat.svg" />
            </IonButton> */}
            <IonButton type="submit" 
            // onClick={()=>{let mainform = document.getElementById("mainform") as HTMLFormElement;if (mainform)mainform.submit();}} 
            slot="start" >
              <IonIcon className="" src="assets/icon/left-arrow.svg" />
            </IonButton>
            <IonTitle slot="start">Edit Profile</IonTitle>
        </IonToolbar>
    </IonHeader>

    <div className="mycontent setupcontainer">
    <PicturesEditContainer save={savePhotos} aspect={4/5} getUserDetails={getUserDetails} />

     
      <div className="setupLabel">

      




      
      <br/>

      <IonLabel color="medium">Name: </IonLabel>
      <IonInput name="name" placeholder="" maxlength={50} ref={register({ required: true, pattern: {value: /^[A-Za-z ]+$/i, message: 'Only letters are allowed.'} })} onIonBlur={(e:any)=>{setUserName((e.target as HTMLInputElement).value); return false;}} value={userName}></IonInput>
      <IonLabel color="warning">{errors.name && (errors.name.message || "Name is required")}</IonLabel>
      <br/>
      <IonLabel color="medium">Where are you from?</IonLabel>
      <IonInput name="city" placeholder="City" maxlength={30} ref={register()} onIonBlur={(e:any)=>{setUserCity((e.target as HTMLInputElement).value); return false;}} value={userCity}></IonInput>
      <br/>
      <div style={{display: "inline-flex", marginTop: 12}}>Date Of Birth</div>
      <IonButton id="open-modal" color='warning' className="zpinkButton">{userDateOfBirth.substring(0,10)}</IonButton>
      <IonModal trigger="open-modal">
        <IonContent>
          <IonDatetime id="dateOfBirth" presentation="date" showDefaultButtons={true} name="dateOfBirth" value={userDateOfBirth}  onIonChange={(e:any)=>{handle_setUserDateOfBirth(e.detail.value!)}}>
          </IonDatetime>
        </IonContent>
      </IonModal>
      <br/><br/><br/>
      <IonLabel color="medium">Gender</IonLabel>
      <IonSelect name="sex" value={userSex} placeholder="select..." interface="popover" onIonChange={(e:any) => handle_setUserSex(e.detail.value)} ref={register({ required: true })} >
        <IonSelectOption value="female">Female</IonSelectOption>
        <IonSelectOption value="male">Male</IonSelectOption>
        <IonSelectOption value="more">More</IonSelectOption>
      </IonSelect>
      <IonLabel color="warning">{errors.sex? "Gender is required" : ""}</IonLabel>
      <br/>
      <IonInput name="sexMore" hidden={userSex != "more"} placeholder="My gender is..." maxlength={30} onIonBlur={(e:any)=>{handle_setUserSexMore((e.target as HTMLInputElement).value); return false;}} value={userSexMore}></IonInput>
      <br/>
      <IonLabel color="medium">About</IonLabel>
      <IonTextarea rows={5} name="about" value={userAbout} placeholder="" maxlength={500} ref={register()} onIonBlur={(e:any)=>{setUserAbout((e.target as HTMLInputElement).value); return false;}}></IonTextarea>
      <br/>
      <IonLabel color="medium">Show Me</IonLabel>
      <IonSelect name="userIIGender" value={userIIGender} placeholder="select..." interface="popover" onIonChange={(e:any) => handle_userIIGender(e.detail.value)} ref={register({ required: true })}>
        <IonSelectOption value="men">Men</IonSelectOption>
        <IonSelectOption value="women">Women</IonSelectOption>
        <IonSelectOption value="everyone">Everyone</IonSelectOption>
      </IonSelect>
      <IonLabel color="warning">{errors.userIIGender? "Show Me (gender) is required" : ""}</IonLabel>
      <br/>
        <IonLabel color="medium">Age Range: {ageRange}</IonLabel>
      <IonRange min={18} max={55} step={1} value={rangeValueInit} snaps={true} dualKnobs={true} 
      onIonChange={(e:any) => setIIAges(e.detail.value as any)}
      onChange={(e:any)=>{alert(e)}}
      // ref={register()}
      >
        <IonLabel slot="start">18</IonLabel>
        <IonLabel slot="end">55+</IonLabel>
      </IonRange>
      
      <br/>


      </div>

      <br/><br/>
<IonButton size="large" id="saveButton" type="submit" className="pinkButton">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Save &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonButton>
<IonButton size="large" id="cancelButton" onClick={() => {history.push("/Profile");}}  className="" color="medium" type="button" >Cancel</IonButton>
      <br/><br/><br/><br/>
      
      <IonButton size="large" id="emailPaswordButton" fill="outline" color="medium" onClick={()=>{setShowEmailPasswordPopover(true)}}>Email and password</IonButton>

      <IonPopover
        isOpen={showEmailPasswordPopover}
        className='emailPasswordDialog'
        onDidDismiss={() => setShowEmailPasswordPopover(false)}>
          <IonItem>
          <IonLabel color="">Email and Password</IonLabel>
          </IonItem>
          <IonItem>
          <IonInput name="email" id="email" value={email} onIonBlur={(e:any)=>{setEmail((e.target as HTMLInputElement).value); return false;}} placeholder="Email" type="email" maxlength={50} ref={register({ required: true, pattern: {value: /^[a-zA-Z0-9_\.+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+$/, message: 'Email not valid.'} })} onIonChange={()=>{setEmailNotConfirmed(false);}} />
          <IonLabel color="warning">{errors.email && errors.email.message}</IonLabel>
          </IonItem>
          {emailNotConfirmed? (
            <div>
              <IonButton id="resendEmailConfirmation" color="warning" onClick={resendEmailConfirmation}>Resend Confirmation</IonButton>
            </div>
          ):(
            ""
          )}
          <IonItem>
          <IonInput name="password" value={password} onBlur={(e:any)=>{setPassword(e.target.value + ""); return false;}} type="password" placeholder="Change password" maxlength={20} ref={register({ required: {value: true, message: 'Password is required'}, minLength: {value: 6, message: 'Password too short.'} })} />
          <IonLabel color="warning">{errors.password && errors.password.message}</IonLabel>
          </IonItem><IonItem>
          <IonInput name="passwordrepeat" value={passwordRetype} onBlur={(e:any)=>{setPasswordRetype(e.target.value + ""); return false;}} type="password" placeholder="Repeat password" ref={register({ required: true})} />
          <IonLabel color="warning">{errors.passwordrepeat && "Please repeat password"}</IonLabel>
          </IonItem>
          <IonItem>
          <IonButton size="large" id="saveEmailPasswordButton" className="pinkButton centeredButton" onClick={emailPasswordChange} type="button" >Save</IonButton>
          </IonItem>
      </IonPopover>
      
      {connectFacebook? (
            <div><br/>
            {isMobile && !isWeb? (
            <IonButton className="" size="large" onClick={() => connectWithFacebook()} expand="full" fill="solid" color="primary">
              Connect to Facebook
            </IonButton>
            ):(""
              // <FacebookLogin 
              // appId="276249436960211"
              // autoLoad={false}
              // fields="name,email,picture" //,public_profile,email,user_photos" //'public_profile', 'email', 'user_photos'
              // scope="public_profile,email,user_photos"
              // // onClick={()=>{alert(1);}}
              // // callback={}
              // onSuccess={responseFacebook} 
              // // disableMobileRedirect={true}
              // // textButton="Connect to Facebook"
              
              // />
            )}
            </div>
          ):(
            ""
          )}


      <br/>
      <br/><br/>
      <IonLabel id="statusLabel" color="primary" className="displayNone">---</IonLabel><br/>
      {/* <IonButton size="large" id="cancelButton" fill="outline" href="Profile" color="medium">Cancel</IonButton> */}
      
      {/* <IonButton size="large" id="saveButton" className="pinkButton" href="Home" type="submit" >Save</IonButton> */}
      <br></br><br></br>
      <IonButton color="medium" onClick={() => {history.push("/SignOut");}} fill="clear">Sign out</IonButton>
        <IonButton color="medium" href="https://www.whotogowith.com/support" target="_blank" fill="clear">Support</IonButton>
        {/* {(hasSubscription)? ( */}
      {/* <IonButton id="cancelSubButton" color="warning" fill="clear" href="/subscription">Subscription</IonButton> */}
      {/* ):""} */}
      <br/><br/>
      <IonLabel color="medium">WhoToGoWith version {versionName}</IonLabel>
      <br/><br/>
      </div>
      </form>
    </div>
    
  );
};


export default SetupContainer;
