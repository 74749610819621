import React, { useState }  from 'react';
import './MPContainer.css';
import { Plugins } from '@capacitor/core';
import { IonButton, useIonViewWillEnter } from '@ionic/react';
import {isMobile} from 'react-device-detect';
import log from 'loglevel';
import {host, webAppURL, isWebApp} from '../App.config.json';
import {loglevelServerSend} from './utils';
import { useHistory } from 'react-router';

interface ContainerProps { }

type Props = {
  id: String,
  eventName: String
}


const ShareContainer: React.FC<Props> = ({id, eventName}) => {

  const history = useHistory();
  const { Storage } = Plugins;

  useIonViewWillEnter(() => {
    loglevelServerSend(log,{});
  });

  return (
      <div className="shareContainer" >
        <a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + webAppURL + "hello?event=" + eventName + (isWebApp? "&display=popup":"")}><img src="assets/icon/sharefb.png"/></a> &nbsp; 
        <a target="_blank" href={webAppURL + "hello?event=" + eventName} onClick={()=>{navigator.clipboard.writeText(webAppURL + "hello?event=" + eventName);}}><img src="assets/icon/sharelink.png"/></a>
      </div>
  )
}

export default ShareContainer;