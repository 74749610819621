import React, { useState } from "react";
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import { base64FromPath } from "@ionic/react-hooks/filesystem";
import { IonPopover, IonItem, IonButton, IonReorderGroup, IonReorder, IonList, IonLoading, useIonViewWillEnter } from "@ionic/react";
import ReactCrop from "react-image-crop";
import { ItemReorderEventDetail } from '@ionic/core/components';
import {host} from '../App.config.json';
import {isMobile, isFirefox, isChrome, isChromium, isEdge, isEdgeChromium, isOpera, isSafari, isMobileSafari, isAndroid, isSamsungBrowser, isBrowser} from 'react-device-detect';
// import axios from 'axios';
import log from 'loglevel';
import {loglevelServerSend} from './utils';

// const maxPhotos = 7;

let userDetails : any;

let _initted = false;

type Props = {
    save?: any,
    maxPhotos?: Number,
    eventId?: String,
    aspect: number,
    getUserDetails?: any
}

const PicturesEditContainer: React.FC<Props> = ({save = ()=>{}, maxPhotos = 7, eventId = null, aspect, getUserDetails = ()=>{return null}}) => {
    
    const [pictures, setPictures] = useState([]);
    const [dummyImgUrl, setDummyImgUrl] = useState("");
    const [showPopover, setShowPopover] = useState(false);
    const [canAddPhotos, setCanAddPhotos] = useState(true);
    const { Storage, Camera } = Plugins;

    const uploadFile = async (file: File, _host: string) => {
      
      openFile(file);
      return;

      // const data = new FormData();
      // data.append('file', file);
      // axios.post(_host + "/uploadPicture?token=" + userDetails.login.token, data, { // receive two parameter endpoint url ,form data 
      //   headers: {
      //       'content-type': 'multipart/form-data'
      //   }
      // })
      // .then(res => { // then print response status
      //   log.info(res.statusText);
      // }).catch((err)=>{log.error(err)}).finally(()=>{setShowPopover(false)});

    }

    const openFile = async (file: File) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
          cropPhoto(reader.result + "");
      };
      reader.onerror = function (error) {
        log.error('Error openFile: ', error);
      };

    }


    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    
    useIonViewWillEnter(() => {

      init();

    });

    const init = () => {
      
      Storage.get({key:"userDetails"}).then((data: any) => {
        if (data.value) {
          userDetails = JSON.parse(data.value);
          // userDetails = getUserDetails();
          loglevelServerSend(log,{user: userDetails});
          if (eventId == null) {
            // alert(userDetails.pictures.join("\n"));
              setPictures(userDetails.pictures);
              if (userDetails.pictures.length >= maxPhotos) {
                  setCanAddPhotos(false);
              }
          } else {
              fetch(host + "/getEvent", {
                  method: 'POST', headers: {
                    'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: eventId})})
                    .then(res => res.json())
                    .then(
                      (result) => {
                        var event = result.eventDetails;
                        if (event) {
                          setPictures(event.pictures);
                        }
                      },
                      (error) => {
                        log.info("error getEvent (" + eventId + "): " + error);
                      }
                    ); // -fetch
                  
          }
        }
      });
    };


    if (!_initted) {
      _initted = true;
      init();
    }

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
        log.trace('Dragged from index', event.detail.from, 'to', event.detail.to);
      
        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        event.detail.complete();
    
        let newPictures = [...pictures];
        let pictureMoved = newPictures.splice(event.detail.from, 1);
        newPictures.splice(event.detail.to, 0, ...pictureMoved);
    
        save(newPictures);

        setPictures([]);
        
        setPictures([...newPictures]);

        // var debugme = "2:\n";
        // for (let i = 0; i < pictures.length; i++) {
        //   if ((pictures[i] + "").indexOf("zB") == 0) debugme += "Cup\n";
        //   else if ((pictures[i] + "").indexOf("vV") == 0) debugme += "Selfie\n";
        //   else if ((pictures[i] + "").indexOf("crw") == 0) debugme += "Lamps\n";
        //   else if ((pictures[i] + "").indexOf("332") == 0) debugme += "Shirt\n";
        //   else if ((pictures[i] + "").indexOf("JZ") == 0) debugme += "Bunny\n";
        // }
        // alert(debugme);
    
      }
    
      const removePicture = async (idx : number) => {
        let newPictures = [...pictures];
        newPictures.splice(idx, 1);
        setPictures(newPictures);
        save(newPictures);
    //     userDetails.pictures.splice(idx, 1);
    //     const userDetailsStringified = JSON.stringify(userDetails);
    //     Storage.set({key:"userDetails", value: userDetailsStringified});
        
    //     fetch(host + "/setup", {
    //       method: 'POST', headers: {
    //         'Content-Type': 'application/json'}, body: userDetailsStringified})
    //         .then(res => res.json())
    //         .then(
    //           (result) => {
    //             if (result.status == "success") {
    //             //   showStatus("Update successful.");
    //               // document.location.href = "/Profile";
    //             } else {
    //             // showStatus("Update failed.");
    //             }
    //           },
    //           (error) => {
    //             log.error("error setup: " + error);
    //             // showStatus("Update failed.");
    //           }
    //         );
    //         // -fetch
            setCanAddPhotos(true);
      }
    
      const [showUsePhotosPopover, setShowUsePhotosPopover] = useState(false);
      const addPicture = async (src : String) => {
        let image :any;
        try {
          image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.DataUrl,
            source: src == "CAMERA"? CameraSource.Camera : CameraSource.Prompt
            });

          // setDummyImgUrl(imageUrl + "");
          // let dummyImg = document.getElementById("dummyImg") as HTMLImageElement;
          // let dummyImg = new HTMLImageElement();
          // dummyImg.src = image.webPath + "";
          // if (dummyImg) alert(dummyImg.clientWidth + " / " + dummyImg.clientHeight);
          // let foo = await fetch(image.webPath!);
          let foo = await fetch(image.dataUrl!);
          let blo = await foo.blob();
          var baba = await convertBlobToBase64(blo) as string;
          cropPhoto(baba);
          // cropPhoto(image.webPath + "");
          
          // Can be set to the src of an image now
          // const base64Data = await base64FromPath(image.webPath!);
          
          // const blob = await fetch(image.webPath + "").then(r => r.blob());
          // const formData = new FormData();
          // formData.append('upl', blob, 'blobby.jpeg');
      
          // uploadPictureBase64(base64Data);
        } catch (e) {
          if (isAndroid && src != "CAMERA") {
            setShowUsePhotosPopover(true);
            // alert("Please use google's 'Photos' app instead of 'Gallery' app");
          }
          log.error("addPicture " + e);
        }
        
    
      }
    
      const uploadPictureBase64 = (base64Data: String, crop: Object) => {
          setShowPopover(false);
          fetch(host + "/addPicture", {
            method: 'POST', headers: {
              'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, base64Data: base64Data, 
                crop: crop, eventId: eventId})})
              .then(res => res.json())
              .then(
                (result) => {
                  if (result.status == "success") {
                    // showStatus("Update successful.");
                    if (eventId == null) {
                        save(result.userDetails.pictures);
                        setPictures(result.userDetails.pictures);
                        if (result.userDetails.pictures.length >= maxPhotos) {
                          setCanAddPhotos(false);
                        }
                    } else {
                        save(result.eventDetails.pictures);
                        setPictures(result.eventDetails.pictures);
                        if (result.eventDetails.pictures.length >= maxPhotos) {
                          setCanAddPhotos(false);
                        }
                    }
                  } else {
                    //   showStatus("Update failed.");
                    log.error("error calling addPicture");
                  }
                  setShowLoadingIndicator(false);
                },
                (error) => {
                  log.error("error uploadPictureBase64: " + error);
                //   showStatus("Update failed.");
                }
              );
              // -fetch
        }
    
        const [showFBAlbumsPopover, setShowFBAlbumsPopover] = useState(false);
        const [fbAlbums, setFbAlbums] = useState([{}]);
        const importPictureFacebook = () => {
          if (getUserDetails()) {
            userDetails = getUserDetails();
          }
          if (!userDetails.login.fbUserId) {
            alert("Connect your account to Facebook first");
            return;
          }
          setShowPopover(false);
          setShowLoadingIndicator(true);
          fetch("https://graph.facebook.com/v7.0/" + userDetails.login.fbUserId + "/albums/?fields=cover_photo.fields(images), count, name&access_token=" + userDetails.login.fbToken, {
                        method: 'GET', headers: {
                          'Content-Type': 'application/json'}})
                          .then(res => res.json())
                          .then(
                            (userAlbumsResult) => {
                              if (userAlbumsResult && userAlbumsResult.data && userAlbumsResult.data.length > 0) {
                                // show albums
                                let albums = [...userAlbumsResult.data];
                                for (let i = 0; i < albums.length; i++) {
                                  let minHeight = 0;
                                  let src = "";
                                  if (albums[i].cover_photo) {
                                    for (let j = 0; j < albums[i].cover_photo.images.length; j++) {
                                      if (minHeight == 0 || minHeight > albums[i].cover_photo.images[j].height) {
                                        minHeight = albums[i].cover_photo.images[j].height;
                                        src = albums[i].cover_photo.images[j].source;
                                      }
                                    }
                                    albums[i].cover_src = src + "";
                                  } else {
                                    albums[i].cover_src = "assets/icon/gallery.svg";
                                  }
                                }
                                setShowLoadingIndicator(false);
                                setFbAlbums(albums);
                                setShowFBAlbumsPopover(true);
                              }
                            },
                            (error) => {
                              log.error("error importPictureFacebook: " + error);
                              setShowLoadingIndicator(false);
                            }); //-fetch
        }
        const [showFBAlbumPhotosPopover, setShowFBAlbumPhotosPopover] = useState(false);
        const [fbPhotos, setFbPhotos] = useState([{}]);
        const loadFbAlbum = (albumId : String) => {
          loadFBPhotos("https://graph.facebook.com/v7.0/" + albumId + "/photos?fields=images&limit=600&access_token=" + userDetails.login.fbToken, true);
        }
        
        const loadFBPhotos = (url: string, firstBunch: Boolean) => {
          setShowLoadingIndicator(true);
          fetch(url, {
                        method: 'GET', headers: {
                          'Content-Type': 'application/json'}})
                          .then(res => res.json())
                          .then(
                            (userAlbumPhotosResult) => {
                              if (userAlbumPhotosResult && userAlbumPhotosResult.data && userAlbumPhotosResult.data.length > 0) {
                                // show photos
                                let photos = [];
                                photos = [...userAlbumPhotosResult.data];
                                
                                for (let i = 0; i < photos.length; i++) {
                                  let minHeight = 0;
                                  let src = "";
                                  if (photos[i].images) {
                                    for (let j = 0; j < photos[i].images.length; j++) {
                                      if (minHeight == 0 || minHeight > photos[i].images[j].height) {
                                        minHeight = photos[i].images[j].height;
                                        src = photos[i].images[j].source;
                                      }
                                    }
                                    photos[i].src = src + "";
                                  } else {
                                    photos[i].src = "assets/icon/gallery.svg";
                                  }
                                }
                                if (userAlbumPhotosResult.paging && userAlbumPhotosResult.paging.next) {
                                  photos[photos.length] = {src: "assets/icon/more2.png", more: userAlbumPhotosResult.paging.next}
                                }
    
                                if (firstBunch) {
                                  setShowLoadingIndicator(false);
                                  setFbPhotos(photos);
                                  setShowFBAlbumPhotosPopover(true);
                                } else {
                                  let oldPhotos = [...fbPhotos];
                                  oldPhotos.length = oldPhotos.length - 1;
                                  photos = [...oldPhotos, ...photos];
                                  setFbPhotos(photos);
                                }
                              }
                            },
                            (error) => {
                              log.error("error loadFbPhotos: " + error);
                              setShowLoadingIndicator(false);
                            }); //-fetch
        }
    
        const importFBPhoto = (photo : any) => {
          let maxHeight = 0;
          let src = "";
          for (let j = 0; j < photo.images.length; j++) {
            if (maxHeight == 0 || maxHeight < photo.images[j].height) {
              maxHeight = photo.images[j].height;
              src = photo.images[j].source;
            }
          }
    
          // import src
          var xhr = new XMLHttpRequest();
          xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
              //uploadPictureBase64(reader.result + "");
              
              cropPhoto(reader.result + "");
              return;
            }
            reader.readAsDataURL(xhr.response);
          };
          xhr.open('GET', src);
          xhr.responseType = 'blob';
          xhr.send();
          setShowFBAlbumPhotosPopover(false);
          setShowFBAlbumsPopover(false);
          setShowPopover(false);
        }
    
        const [crop, setCrop] = useState(Object);
        const [showCropPhotoPopover, setShowCropPhotoPopover] = useState(false);
        const cropPhoto = (imageUrl: any) => {
          
          setShowCropPhotoPopover(true);
          setDummyImgDivClassName("");
          if (imageUrl) setDummyImgUrl(imageUrl);

        }

        const cropImageLoaded = (img: HTMLImageElement) => {
          
          setTimeout(function(){
            // We use setTimeout because before the image size is huge, after timeout it's much smaller and ready
            
            // let image = document.getElementById("dummyImg") as HTMLImageElement;
            // let image = document.getElementsByClassName("ReactCrop__image")[0] as HTMLImageElement;
            let image = img;
            if (image.width / image.height < aspect) {
              let height = image.width / aspect;
              // alert("1: " + image.height + " > " + height);
              setTimeout(function() {
              setCrop({
                unit: 'px',
                width: image.width,
                height: height,
                x: 0, y: (image.height - height) / 2,
                aspect: aspect});
              }, 500);
            } else {
              let width = aspect * image.height;
              // alert("2: " + image.width + " > " + width);
              setTimeout(function() {
              setCrop({
                unit: 'px',
                width: width,
                height: image.height,
                x: (image.width - width)/2, y: 0,
                aspect: aspect});
              }, 500);
            }
          },350);
    
        }
        let convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
          const reader = new FileReader;
          reader.onerror = reject;
          reader.onload = () => {
              resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        });

        const doCrop = async () => {
          
          //alert(crop.x + " / " + crop.y + "\n" + crop.width + " / " + crop.height);
          
          // let image = document.getElementById("dummyImg") as HTMLImageElement;
          let image = document.getElementsByClassName("ReactCrop__image")[0] as HTMLImageElement;
          image.src = dummyImgUrl;
          const canvas = document.createElement('canvas');
          const scaleX = image.naturalWidth / image.width;
          const scaleY = image.naturalHeight / image.height;
          
          //alert(scaleX + " = " + (image.naturalWidth / image.width));
          

          canvas.width = crop.width * scaleX;
          canvas.height = crop.height * scaleY;
          const ctx = canvas.getContext('2d');
          // if (ctx) ctx.drawImage(image,0,0);
          // alert(canvas.toDataURL('image/jpeg'));

          // Size limit
          let sizeLimitMulti = 1;
          if (aspect < 1 && canvas.height > 850) {
            sizeLimitMulti = 850 / canvas.height;
            canvas.height = 850;
            canvas.width = canvas.height * aspect;
          } else if (aspect > 1 && canvas.width > 850) {
            sizeLimitMulti = 850 / canvas.width;
            canvas.width = 850;
            canvas.height = canvas.width / aspect;
          } else if ((aspect < 1 && image.naturalHeight < 600) || (aspect > 1 && image.naturalWidth < 600)) {
            alert("Image too small, pick a different one.");
            setShowCropPhotoPopover(false);
            return;
          } else if ((aspect < 1 && canvas.height < 600) || (aspect > 1 && canvas.width < 600)) {
            alert("Crop is too small");
            return;
          } 
          
          if (ctx)
          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX * sizeLimitMulti,
            crop.height * scaleY * sizeLimitMulti,
          );
    
          // As Base64 string
          const base64Image = canvas.toDataURL('image/jpeg');
          const base64Data = await base64FromPath(base64Image); //dummyImgUrl
          uploadPictureBase64(base64Data, {x: crop.x * scaleX, y: crop.y * scaleY, width: crop.width * scaleX * sizeLimitMulti, height: crop.height * scaleY * sizeLimitMulti});
          setDummyImgDivClassName("dummyImgDiv");
          setShowCropPhotoPopover(false);
          setShowPopover(false);
          setShowLoadingIndicator(true);
          setDummyImgUrl("#"); // This fixes the bug of 14 sec freeze when uploading 2nd image
        }
      const [dummyImgDivClassName, setDummyImgDivClassName] = useState("dummyImgDiv");
    
      const addPhoto = () => {
        
        if (pictures.length < maxPhotos) {
          setShowPopover(true);
        }
      }

      const uploadPicture = () => {
        let fileUploadInput = document.getElementById("fileUploadInput");
        if (fileUploadInput) {
          (fileUploadInput as HTMLInputElement).click();
        }
      }

    return (
        <div>

          <IonLoading isOpen={showLoadingIndicator} backdropDismiss={true}></IonLoading>
            
     <IonPopover
        isOpen={showCropPhotoPopover}
        className='cropDialog'
        onDidDismiss={() => setShowCropPhotoPopover(false)}>
          
          <ReactCrop crop={crop} src={dummyImgUrl} onImageLoaded={cropImageLoaded} 
          onChange={(newCrop:any) => setCrop(newCrop)}
           />
          
          <IonItem>
          <div className="cropButtonDiv"><IonButton size="large" id="cropButton" onClick={doCrop} className="pinkButton centeredButton" type="button" >OK</IonButton></div>
          </IonItem>
      </IonPopover>


     <br/>



          <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
            {pictures.map((picture, idx) =>
            <IonItem key={idx} className="profileThumbReorder">
              <img src={"https://gowith.s3.us-east-2.amazonaws.com/" + (eventId? "events/" + eventId : "users/" + userDetails.id) + "_" + picture + ".jpeg"} className="profileThumb"/>
              <IonButton slot="end" onClick={()=>removePicture(idx)} color="warning">Remove</IonButton>
              <IonReorder slot="end" />
            </IonItem>
            )}
          </IonReorderGroup>

    <br/>

    <IonPopover
        isOpen={showFBAlbumsPopover}
        className='my-custom-class'
        onDidDismiss={() => setShowFBAlbumsPopover(false)}
      >
        <IonList>
          {fbAlbums.map ((album:any) => 
          
          <IonItem key="{album.id}" onClick={()=>{loadFbAlbum(album.id);}}><div><table><tr><td className="fbAlbumThumbTD"><img className="fbAlbumThumb" src={album.cover_src}/></td><td>{album.name}<div className="smallGreyLabel">{album.count}</div></td></tr></table></div></IonItem>

          )}
        </IonList>
      </IonPopover>



    <IonPopover
        isOpen={showFBAlbumPhotosPopover}
        className='my-custom-class'
        onDidDismiss={() => setShowFBAlbumPhotosPopover(false)}>

          {fbPhotos.map ((photo:any, index) => 
          
            <img key="{index}" className="fbPhotoThumb" src={photo.src}
            onClick={()=>{if (photo.more) {loadFBPhotos(photo.more, false);} else {importFBPhoto(photo)}}}
            />

          )}
      </IonPopover>



    <IonPopover
        isOpen={showPopover}
        className='my-custom-class'
        onDidDismiss={() => setShowPopover(false)}
      >
        <IonList>
          <IonItem onClick={()=>{addPicture("CAMERA")}}><img src="assets/icon/camera.svg" className="addPhotoIcon"/> Camera</IonItem>
          {isMobile && !(isFirefox || isChrome || isChromium || isEdge || isEdgeChromium || isOpera || isSafari || isMobileSafari || isSamsungBrowser || isBrowser)? (
          <IonItem onClick={()=>{addPicture("PHOTOS")}}><img src="assets/icon/gallery.svg" className="addPhotoIcon"/> Gallery</IonItem>
          ):(
            <IonItem onClick={()=>{uploadPicture()}}><img src="assets/icon/gallery.svg" className="addPhotoIcon"/> Upload a file <input className="fileUploadInput" id="fileUploadInput" type="file" onChange={(e:any)=>{uploadFile(e.target.files![0], host)}}/> </IonItem>
          )}
          {/* <IonItem onClick={()=>{alert("Comming soon...")}}><img src="assets/icon/instagram.png" className="addPhotoIcon"/> Instagram</IonItem>
          <IonItem onClick={()=>{importPictureFacebook()}}><img src="assets/icon/facebook.png" className="addPhotoIcon"/> Facebook</IonItem> */}
        </IonList>
      </IonPopover>
      <IonPopover
        isOpen={showUsePhotosPopover}
        className='my-custom-class'
        onDidDismiss={() => setShowUsePhotosPopover(false)}
      >
        <div onClick={()=>{setShowUsePhotosPopover(false)}} className="warningPopupPadding">
          <br/>
        Please use google's 'Photos' app instead of 'Gallery' app
        <br/><br/>
        <img src="assets/use-photos.jpg"/>
        <br/><br/>
        </div>
      </IonPopover>
    <IonButton id="addPicture" className="pinkButton" 
    onClick={()=>{addPhoto();}}
    disabled={!canAddPhotos}
    >Add Picture</IonButton>
     <br/><br/>

     <div className={dummyImgDivClassName}>
        <img src="{dummyImgUrl}" className="dummyImg" id="dummyImg"/>
        </div>

        </div>
    )
}

export default PicturesEditContainer;
