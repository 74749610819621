import React  from 'react';
import { Plugins } from '@capacitor/core';


import './MPContainer.css';
import { useHistory } from 'react-router';
import { useIonViewWillEnter } from '@ionic/react';

interface ContainerProps { }

const SignInContainer: React.FC<ContainerProps> = () => {

  const history = useHistory();
  const { Storage } = Plugins;

  useIonViewWillEnter(() => {
    // Storage.remove({key:'userDetails'});
    Storage.clear();
    history.push("/Hello");
  });

  return (
    <div className="mpcontainer">
     signed out
    </div>
    
  );
};


export default SignInContainer;
