import React, { useState } from 'react';
import { IonLabel, IonIcon, IonFab, IonFabButton, IonInput, useIonViewWillEnter, IonicSlides } from '@ionic/react';
import './MPContainer.css';
import { Plugins } from '@capacitor/core';
import {swipeSlider} from './utils'
import {isIOS} from 'react-device-detect';
import { useHistory } from "react-router-dom";
import {host} from '../App.config.json';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Zoom } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/modules/keyboard/keyboard.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import 'swiper/modules/zoom/zoom.min.css';
import '@ionic/react/css/ionic-swiper.css';

interface ContainerProps { }

let userDetails : any;

let slider : any;

const ProfileContainer: React.FC<ContainerProps> = () => {

  const history = useHistory();

  const { Storage } = Plugins;

  const [wideScreenMode, setWideScreenMode] = useState(false);

  
  const [userName, setUserName] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userAbout, setUserAbout] = useState("");
  const [pictures, setPictures] = useState([]);
  
  useIonViewWillEnter(() => {
    
    window.scrollTo({top: 0, behavior: "smooth"});

    if (window.innerWidth > 800) {
      setWideScreenMode(true);
    }

    (async () => {

      let userDetailsStr = await Storage.get({key:"userDetails"});
      userDetails = JSON.parse(userDetailsStr.value + "");
      loglevelServerSend(log,{user: userDetails});
      
      fetch(host + "/getProfile", {
        method: 'POST', headers: {
          'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, toUserId: userDetails.id})})
          .then(res => res.json())
          .then(
            (result) => {
              if (result.status == "success") {
                // profile = result.profile;
                userDetails = result.profile;
                const userDetailsStringified = JSON.stringify(userDetails);
                Storage.set({key:"userDetails", value: userDetailsStringified});
              } else {
                log.error("error getProfile of self." + userDetails.id);
              }

              // Continue init stuff
              setUserName(userDetails.name);
              let diff_ms = Date.now() - new Date(userDetails.dob).getTime();
              let age_dt = new Date(diff_ms); 
              setUserAge(Math.abs(age_dt.getUTCFullYear() - 1970) + "");
              
              if (userDetails.city && userDetails.city.length > 1) setUserCity("Lives in " + userDetails.city);
              else setUserCity("");

              setUserAbout(userDetails.about);
              setPictures(userDetails.pictures);
              
            },
            (error) => {
              log.info("error: " + error);
            }
          );
          // -fetch

    })();

  });

  const [pictureIdx, setPictureIdx] = useState(0); // scroll through pictures in wide screen mode

  return (
    <div className={"mpcontainer maincontainer" + (isIOS? " " : "")}>
      {wideScreenMode? (
        <div>
          <br/><br/>
          {pictures.length > 0? (
          <img onClick={(e:any)=>{
            if (pictures.length > (pictureIdx + 1)) {
              setPictureIdx(pictureIdx + 1);
            } else {
              setPictureIdx(0);
            }
            (e.target as HTMLImageElement).src = "https://gowith.s3.us-east-2.amazonaws.com/users/" + userDetails.id + "_" + pictures[pictureIdx] + ".jpeg";
          }}
           src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + userDetails.id + "_" + pictures[pictureIdx] + ".jpeg"} className="profilePhotoWide cursorHand"/>
        ):""}
        {pictures.length == 0? (
         <img src={"assets/icon/defaultuserphoto.svg"} className="profilePhotoWide"/>
        ):("")}
        </div>
      ):(
        <span>
        {pictures && pictures.length > 0? (
          <span>
          <Swiper modules={[Pagination, Zoom, IonicSlides]} zoom={true} pagination={true} onClick={(e:any)=>{if (wideScreenMode) swipeSlider(e, slider);}}>
            {pictures.map(picture =>
              <SwiperSlide>
                <img src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + userDetails.id + "_" + picture + ".jpeg"} className={wideScreenMode? " profilePhotoWide" : "profilePhoto"}/>
              </SwiperSlide>
            )}
          </Swiper>
          </span>
        ) : (
        <Swiper modules={[Pagination]} pagination={true}>
          <SwiperSlide>
            <img src={"assets/icon/defaultuserphoto.svg"} className="profilePhoto"/>
          </SwiperSlide>
        </Swiper>
        )}
        </span>)}
    
    <IonFab vertical="bottom" horizontal="end" slot="fixed" className="editiconfab">
      <IonFabButton onClick={()=>{history.push("/Setup")}}>
        <IonIcon  className="editicon" icon="assets/icon/editinfo.svg" />
      </IonFabButton>
    </IonFab>
    <br/><br/>
    <div className="profileDetails">
  <IonLabel className="profileName"><b>{userName}</b> {userAge == "NaN"? "": ", " + userAge}</IonLabel><br/>
  <IonLabel className="profileCity">{userCity}</IonLabel>
    
    <br/><br/>
    <div className={"profileBio" + (userAbout && /[א-ת].+/.test(userAbout.trim())? " rtl":"")} dir="">
    <span className="preLine">{userAbout}</span>
    
    </div>
    <br/><br/><br/>
    </div>

    
    
    </div>
  );
};

export default ProfileContainer;
