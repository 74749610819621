import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';
import {host} from './App.config.json';
// import {w3cwebsocket} from 'websocket';
import { IonToast } from '@ionic/react';
import {isMobile, isIOS, isAndroid} from 'react-device-detect';
// import { FCM } from 'capacitor-fcm';
// import { FCM } from '@ionic-native/fcm';
import socketIOClient from "socket.io-client";
import {setUnreadMessages, newMessage, newMatch} from './components/utils';

import log from 'loglevel';
import {loglevelServerSend} from './components/utils';

const { PushNotifications } = Plugins;

const { Storage } = Plugins;
const { LocalNotifications } = Plugins;

let userDetails : any;
let client : any;
// const fcm = FCM.;
// const { FCMPlugin } = Plugins;

(window as any).unreadMsgsListeners = [];
(window as any).newMsgsListeners = [];
(window as any).newMatchListeners = [];

async function init () {
    let userDetailsStr = await Storage.get({key:"userDetails"});
    if (!userDetailsStr.value && ['/signout', '/signup', '/signin', '/hello'].indexOf(window.location.pathname.toLowerCase()) == -1) {
      document.location.href = "/Hello";
    }
  
    
    ReactDOM.render(<App />, document.getElementById('root'));

    if (userDetailsStr.value) {
      userDetails = JSON.parse(userDetailsStr.value + "");
      loglevelServerSend(log,{user: userDetails});
      setupNotifications(userDetails);
    }
}

async function setupNotifications (userDetails: any) {
    
  
    if (isMobile) {

      try {
        
        await PushNotifications.removeAllListeners();
        
        PushNotifications.addListener('registration',
          (token: PushNotificationToken) => {

            // alert('Push registration success, token: ' + token.value + " " + userDetails.pushNotificationsToken != token.value);
            if (!userDetails.pushNotifications || userDetails.pushNotifications.token != token.value) {
              fetch(host + "/updatePushNotificationsToken", {
                method: 'POST', headers: {
                  'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, pushNotificationsToken: token.value, platform: isIOS? "ios" : isAndroid? "android" : "other"})})
                  .then(res => res.json())
                  .then(
                    (result) => {
                      if (result.status == "success") {
                        const userDetailsStringified = JSON.stringify(result.userDetails);
                        Storage.set({key:"userDetails", value: userDetailsStringified});
                      } else {
                        log.error("error updatePushNotificationsToken");
                      }
                    },
                    (error) => {
                      log.error("error (updatePushNotificationsToken): " + error);
                    }
                  );
                  // -fetch
            }
          }
        );
        
        // Some issue with your setup and push will not work
        PushNotifications.addListener('registrationError',
          (error: any) => {
            // alert('Error on registration: ' + JSON.stringify(error));
            log.error("Error on registration " + JSON.stringify(error));
            // alert("Error on registration " + JSON.stringify(error));
            
          }
        );
        
        
        // PushNotifications.register();
        PushNotifications.requestPermission().then( result => {
          if (result.granted) {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            log.error("Error on requestPermission " + JSON.stringify(result));
            // Show some error
          }
        });
        //
        // PushNotifications.requestPermission().then(result => {
        //   if (result.granted) {
        //     // Register with Apple / Google to receive push via APNS/FCM
        //     PushNotifications.register().then(() => {
        //       // let fcmCheck = setInterval(() => { 
        //       //   if (typeof FCMPlugin != 'undefined') { 
        //       //     alert("ya");
        //       //     FCMPlugin.subscribeTo({ topic: 'general' }); 
        //       //     clearInterval(fcmCheck);  
        //       //   } 
        //       // }, 1000);
        //       return FCM.subscribeToTopic('general');
        //     });
        //   } else {
        //     log.error('There was a problem with registering for notifications');
        //   }
        // });
    
        // FCM.onNotification().subscribe(data => {
        //   if(data.wasTapped){
        //     alert("Received in background");
        //   } else {
        //     alert("Received in foreground");
        //   };
        // });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotification) => {
            // alert("pushNotificationReceived: " + notification.data.type + " - " + notification.data.fromUserId);
            if (notification.data.type == "message") {
              showNotification("New message from " + notification.data.name);
            } else if (notification.data.type == "newmatch") {
              showNotification("You matched with " + notification.data.name + " 😍😍");
              setUnreadMessages(true);
              newMatch();
            }
          }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
          (notification: PushNotificationActionPerformed) => {
            // alert("pushNotificationActionPerformed: " + notification.notification.data.id + ": " + notification.notification.data.title + " > " + notification.notification.data.body);
            if (notification.notification.data.type == "message") {
              showNotification("New message from " + notification.notification.data.name);
            }
          }
        );

      } catch (err) {
        log.error(err); // may happen in webmob emulation
      }

    }

    // Foreground notifications (bcs push notifs pushNotificationReceived listener won't fire)
    const socket = socketIOClient(host + "", {
      query: {userId: userDetails.id, type: "notifications"}
    });
    
    socket.on("FromAPI", async (data: any) => {
      log.info(data);
      let dataObj = JSON.parse(data);
      
      if (dataObj.type == "message") {

        // Chats screen uses this event to update last msg snippet
        newMessage(dataObj);
        setUnreadMessages(true);

        // only notify new message in case it's the first from this user
        let newMsgFrom = await Storage.get({key:"newMsgFrom_" + dataObj.fromUserId});
        if (!newMsgFrom || !newMsgFrom.value) {
            Storage.set({key:"newMsgFrom_" + dataObj.id, value: "1"});

            // this will update the top chats icon to reflect new unread messages
            let unreadStr = await Storage.get({key:"unread"});
            let unread : any;
            if (!unreadStr || !unreadStr.value) {
              unread = JSON.parse(unreadStr.value + "");
            } else {
              unread = [dataObj.id]
            }
            Storage.set({key:"unread", value: JSON.stringify(unread)});

            showNotification("New message from " + dataObj.name);
        }
      } else if (dataObj.type == "newmatch") {
        showNotification("You matched with " + dataObj.name + " 😍😍");
        setUnreadMessages(true);
        newMatch();
      } else if (dataObj.type == "unread") {
        setUnreadMessages(true);
      } else if (dataObj.type == "unmatched") {
        newMatch(); // just causes reload in chats screen
      } else if (dataObj.type == "newlike") {
        showNotification("New like in " + dataObj.name + " 👍");
      }
      
    });

    // -end Foreground notifications

    // client = new w3cwebsocket("ws" + host.substr(4) + "/notifications");
    // client.onopen = () => {
    //   if (userDetails.login && userDetails.login.token)
    //     client.send(JSON.stringify({token: userDetails.login.token, type: "login"}));
    // }

    // client.onmessage = async (msg:any) => {
    //     const dataFromServer = JSON.parse(msg.data.toString());

    //     let notificationsDIV = document.getElementById('notifications');
    //     var newDiv = document.createElement("div");
    //     if (notificationsDIV) {
    //         notificationsDIV.appendChild(newDiv);
    //     }

    //     if (dataFromServer.type == "message") {
    //         const notifs = await LocalNotifications.schedule({
    //             notifications: [
    //               {
    //                 title: "whotoGoWith.com",
    //                 body: "New message from " + dataFromServer.name,
    //                 id: Math.floor(Math.random() * (10000000 - 10000) + 100),
    //                 schedule: { at: new Date(Date.now() + 1000 * 1) },
    //                 // sound: null,
    //                 // attachments: null,
    //                 actionTypeId: "",
    //                 extra: null
    //               }
    //             ]
    //           }); // -notif
    //         let userDetailsStr = await Storage.get({key:"newMsgFrom_" + dataFromServer.id});
    //         if (!userDetailsStr || !userDetailsStr.value) {
    //             Storage.set({key:"newMsgFrom_" + dataFromServer.id, value: "1"});
    //             ReactDOM.render(<IonToast
    //                 isOpen={true}
    //                 onDidDismiss={()=>{let notificationsDiv = document.getElementById('notifications'); if (notificationsDiv) notificationsDiv.innerHTML = "";}}
    //                 message={"New message from " + dataFromServer.name}
    //                 duration={2000}
    //             />, newDiv);
    //             //notif here
    //         }
    //     } else if (dataFromServer.type == "newmatch") {
    //         ReactDOM.render(<IonToast
    //             isOpen={true}
    //             onDidDismiss={()=>{let notificationsDiv = document.getElementById('notifications'); if (notificationsDiv) notificationsDiv.innerHTML = "";}}
    //             message={"You matched with " + dataFromServer.name + " 😍😍"}
    //             duration={2000}
    //           />, newDiv);
    //           const notifs = await LocalNotifications.schedule({
    //             notifications: [
    //               {
    //                 title: "whotoGoWith.com",
    //                 body: "You matched with " + dataFromServer.name + " 😍😍",
    //                 id: Math.floor(Math.random() * (10000000 - 10000) + 100),
    //                 schedule: { at: new Date(Date.now() + 10 * 1) },
    //                 // sound: null,
    //                 // attachments: null,
    //                 actionTypeId: "",
    //                 extra: null
    //               }
    //             ]
    //           }); // -notif
    //     }
    // }
    // client.onclose = function() {
    //     log.info("ws closed");
    // }
    // client.onerror = function() {
    //     log.error("error in ws");
    // }
}
init();

function showNotification (txt: String) {

  let notificationsDIV = document.getElementById('notifications');
  var newDiv = document.createElement("div");
  if (notificationsDIV) {
      notificationsDIV.appendChild(newDiv);
  }
  ReactDOM.render(<IonToast
    isOpen={true}
    onDidDismiss={()=>{let notificationsDiv = document.getElementById('notifications'); if (notificationsDiv) notificationsDiv.innerHTML = "";}}
    message={txt + ""}
    duration={2000}
/>, newDiv);
}

  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
