import React, { useState }  from 'react';
import './MPContainer.css';
import {host} from '../App.config.json';
import { Plugins } from '@capacitor/core';
import { IonButton, useIonViewWillEnter } from '@ionic/react';
import {isMobile} from 'react-device-detect';
import log from 'loglevel';
import {loglevelServerSend} from './utils';

// import AppleLogin from 'react-apple-login';
import { AppleLogin } from 'react-sign-in-apple'
import { useHistory } from 'react-router';

interface ContainerProps { }

const AppleSignInUpContainer: React.FC<ContainerProps> = () => {

  const history = useHistory();
  const { Storage } = Plugins;

  const [isWeb, setIsWeb] = useState(false);

  useIonViewWillEnter(() => {
    loglevelServerSend(log,{});
  });

    
  const appleSignInCB = (res: any) => {
    const token_id = res.authorization.id_token;
    const name = res.user && res.user.name? res.user.name.firstName + " " + res.user.name.lastName : null;
    const email = res.user? res.user.email : null;
  
    log.info('Apple access token is ' + token_id + ', name is ' + name + ' and email is ' + email + '}');
      
    // try login with apple token_id
    fetch(host + "/signinApple", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token_id: token_id})})
        .then(res => res.json())
        .then(
          (signInResult) => {
            if (signInResult.status == "fail") {
              // register
              fetch(host + "/signupApple", {
                method: 'POST', headers: {
                  'Content-Type': 'application/json'}, body: JSON.stringify({name: name, email: email, token_id: token_id})})
                  .then(res => res.json())
                  .then(
                    (result) => {
                      if ("success" == result.status) {
                        Storage.set({key:"userDetails", value: JSON.stringify(result.userDetails)});
                        history.push("/Setup");
                        
                      } else if ("exists" == result.status && result.email && result.email.length > 0) {
                        Storage.set({key:"userAlreadyExists", value: result.email}); 
                        history.push("/SignIn");
                      }
                    },
                    (error) => {
                      log.error("error signupApple (" + token_id + " / " + name + " / " + email + "): " + error);
                      alert(error);
                    }
                  );
            } else if (signInResult.status == "success") {
              Storage.set({key:"userDetails", value: JSON.stringify(signInResult.userDetails)});
              history.push("/Events");
            }
          
          },
          (error) => {
            log.error("error appleSignIn2 (" + token_id + "): " + error);
          }
        );
      }

      return (
          <div>

         {/* <AppleLogin clientId="com.whotogowith.serviceid" usePopup={true} callback={appleCB} redirectURI="https://app.whotogowith.com/hello" /> */}
      <AppleLogin
      clientId="com.whotogowith.serviceid"
      redirectURI="https://app.whotogowith.com/hello"
      onSuccess={appleSignInCB}
      usePopup={true}
      scope="name email"
      responseMode="form_post"
      onFailure={console.error}
      render={({ onClick }) => (
        <IonButton onClick={onClick} color="medium" size="default" fill="solid" style={{height: 50, width: 260}}>Sign in with Apple</IonButton>
      )}
      />
      
      </div>
      )
}

export default AppleSignInUpContainer;