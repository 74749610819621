import React from 'react';
import { IonButton, IonBadge, IonGrid, IonRow, IonCol, useIonViewWillEnter } from '@ionic/react';
import './MPContainer.css';
import { Plugins } from '@capacitor/core';
import {getEventDateStr} from './utils';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import ReportContainer from './ReportContainer';
import ShareContainer from './ShareContainer';

interface ContainerProps {
  go?: any,
  show?: any,
  eventId?: String,
  eventName?: String,
  eventTypeStr?: String,
  organizerName?: String,
  eventPicture?: String,
  statsGoingMen?: Number,
  statsGoingWomen?: Number,
  statsGoingAgesRange?: String,
  eventLocation?: String,
  eventStart?: String,
  eventAbout?: String,
  eventGoing?: Boolean,
  wideScreenMode: Boolean
 }


const EventContainer: React.FC<ContainerProps> = ({go = () => {}, show = () => {}, eventId = "", eventName = "", eventTypeStr = "", organizerName = "",
eventPicture = "", statsGoingMen = 0, statsGoingWomen = 0, statsGoingAgesRange = "", eventLocation = "", eventStart = "", eventAbout = "", eventGoing = false, wideScreenMode = false}) => {

  const { Storage } = Plugins;
  const { Keyboard } = Plugins;


  // const [wideScreenMode, setWideScreenMode] = useState(false);

  useIonViewWillEnter(() => {
    loglevelServerSend(log,{});
  });

  const hideKeyboard = () => {
    Keyboard.hide().catch((e)=>{});
  }

  return (
    !wideScreenMode? (
        <div className="foundEvent" onTouchStart={()=>{hideKeyboard();}}>
          <div className="leftalign-nofloat line-height-big organizerAddedEvent">
          <b>{organizerName}</b> has added {(['a','i','o','u','e'].indexOf(eventTypeStr.toLowerCase()[0]) > -1? "an " : "a ") + eventTypeStr}
          </div>
          <div className="eventImgDiv">
          <img src={eventPicture == ""? "assets/icon/defaulteventphoto.svg" : "https://gowith.s3.us-east-2.amazonaws.com/events/" + eventId + "_" + eventPicture + ".jpeg"} className="eventPhoto"/>
          <div className="eventBadges">
            <IonBadge slot="end" color="medium" hidden={statsGoingMen == -1}><img src="assets/icon/men.svg" className="verticalMiddle" /> {statsGoingMen}</IonBadge>
             &nbsp; 
            <IonBadge slot="end" color="medium" hidden={statsGoingWomen == -1}><img src="assets/icon/women.svg" className="verticalMiddle" /> {statsGoingWomen}</IonBadge>
            &nbsp; 
            <IonBadge slot="end" color="medium" hidden={statsGoingAgesRange == ""}><img src="assets/icon/ages.svg" className="verticalMiddle" /> {statsGoingAgesRange}</IonBadge>
          </div>
          </div>

        <IonButton className={eventGoing? "eventAttendingButton greenButton eventAttendingButtonGoing" : "eventAttendingButton pinkButton"} 
        onClick={()=>{if (eventGoing) show(eventId); else go(eventId); }}>{eventGoing? "GOING" : "GO"}</IonButton>
          <div className="leftalign-nofloat line-height-big stretchDiv">
            <span className="pinkLabel smallLabel">{getEventDateStr(eventStart + "")}</span>
            <br/>
            <b>{eventName}</b>
            <br/>
            <span className="smallGreyLabel">{eventLocation}</span>
          </div>
            <div className="line-above leftalign-nofloat eventDesc">
              <div className={"mediumGreyLabel" + (/[א-ת].+/.test(eventAbout + "")? " rtl":"")}><span className="preLine">{eventAbout}</span></div>
            </div>
          <br/>
          <ShareContainer id={eventId} eventName={eventName}></ShareContainer>
          <ReportContainer type="event" id={eventId}></ReportContainer>
          <br/>
        </div>
    ): (
      <div className="foundEventWide">
        <IonGrid>
          <IonRow>
            <IonCol>
            <div className="eventImgDiv">
          <img src={!eventPicture || eventPicture == "" || eventPicture == "undefined"? "assets/icon/defaulteventphoto.svg" : "https://gowith.s3.us-east-2.amazonaws.com/events/" + eventId + "_" + eventPicture + ".jpeg"} className="eventPhoto"/>
          <div className="eventBadges">
          <IonBadge slot="end" color="medium" hidden={statsGoingMen == -1}><img src="assets/icon/men.svg" className="verticalMiddle" /> {statsGoingMen}</IonBadge>
             &nbsp; 
            <IonBadge slot="end" color="medium" hidden={statsGoingWomen == -1}><img src="assets/icon/women.svg" className="verticalMiddle" /> {statsGoingWomen}</IonBadge>
            &nbsp; 
            <IonBadge slot="end" color="medium" hidden={statsGoingAgesRange == ""}><img src="assets/icon/ages.svg" className="verticalMiddle" /> {statsGoingAgesRange}</IonBadge>
          </div>
          </div>
            </IonCol>
            <IonCol>
            <div className="leftalign-nofloat line-height-big organizerAddedEvent">
          <b>{organizerName}</b> has added {(['a','i','o','u','e'].indexOf(eventTypeStr.toLowerCase()[0]) > -1? "an " : "a ") + eventTypeStr}
          </div>
          

        <IonButton className={eventGoing? "eventAttendingButton greenButton eventAttendingButtonGoing" : "eventAttendingButton pinkButton"} 
        onClick={()=>{if (eventGoing) show(eventId); else go(eventId);}} >{eventGoing? "GOING" : "GO"}</IonButton>
          <div className="leftalign-nofloat line-height-big stretchDiv">
            <span className="pinkLabel smallLabel">{getEventDateStr(eventStart + "")}</span>
            <br/>
            <b>{eventName}</b>
            <br/>
            <span className="smallGreyLabel">{eventLocation}</span>
          </div>
            <div className="line-above leftalign-nofloat eventDesc">
              <div className={"mediumGreyLabel" + (/[א-ת].+/.test(eventAbout + "")? " rtl":"")}><span className="preLine">{eventAbout}</span></div>
            </div>
          <br/>
          <ShareContainer id={eventId} eventName={eventName}></ShareContainer>
          <ReportContainer type="event" id={eventId}></ReportContainer>
          <br/>
            </IonCol>
          </IonRow>
        </IonGrid>
          
        </div>
    )
    
  );
};

export default EventContainer;
