import { IonContent, IonHeader, IonPage, IonToolbar, IonButton, IonIcon, IonGrid, IonRow, IonCol, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import { Plugins } from '@capacitor/core';
//import ExploreContainer from '../components/ExploreContainer';
import SwipeContainer from '../components/SwipeContainer';
import ExitContainer from '../components/ExitContainer';
import {isWebApp as isWebApp} from '../App.config.json';
import { useHistory } from 'react-router-dom';

const Swipe: React.FC = () => {

  const history = useHistory();

  const { Storage } = Plugins;

  const [wideScreenMode, setWideScreenMode] = useState(window.innerWidth > 800);

  const [unreadMessages, setUnreadMessages] = useState(false);
  const unreadMsgs = (unread: boolean) => {
    setUnreadMessages(unread);
  }

  useIonViewWillEnter(() => {
    
    (window as any).unreadMsgsListeners.push(unreadMsgs);
      
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <img className="topicon" height={36} src={"assets/icon/" + (wideScreenMode? "logo_004.png" : "logo-notext-s.png")} style={{float: "left", paddingLeft: wideScreenMode? "4%" : 12, marginTop: 2, marginRight: wideScreenMode && isWebApp == "true"? -132:0}} />
            <div className="topToolBar">
            <IonButton onClick={()=>{history.push("/Profile")}} className="topiconButton">
            {wideScreenMode? (
                <img className="topicon topicon-web" src="assets/icon/profile-web.svg" />
              ) : (
                <IonIcon className="topicon" src="assets/icon/profile.svg" />
              )}
            </IonButton>
            <IonButton onClick={()=>{history.push("/Events")}} className="topiconButton">
            {wideScreenMode? (
                <img className="topicon topicon-web" src={"assets/icon/events-web.svg"} />
              ) : (
                <IonIcon className="topicon" src="assets/icon/events.svg" />
              )}
            </IonButton>
            <IonButton className="topiconButton">
            {wideScreenMode? (
                <img className="topicon topicon-web" src={"assets/icon/swipe-on-web.svg"} />
              ) : (
                <IonIcon className="icon-only" src="assets/icon/swipe-on.svg" />
              )}
            </IonButton>
            <IonButton onClick={()=>{history.push("/Chats")}} className="topiconButton">
            {wideScreenMode? (
                <img className="topicon topicon-web" src={unreadMessages? "assets/icon/chat-unread-web.svg" : "assets/icon/chat-web.svg"} />
              ) : (
                <IonIcon slot="icon-only" src={unreadMessages? "assets/icon/chat-unread.svg" : "assets/icon/chat.svg"} />
              )}
            </IonButton>
            </div>
        </IonToolbar>
    </IonHeader>

    <IonContent>
    {wideScreenMode? (
        <IonGrid>
          <IonRow>
          <IonCol className="sideCol" size="1"></IonCol>
            <IonCol className="mainCol" size="10">
              <div className="mainDiv">
                <SwipeContainer/>
                <ExitContainer></ExitContainer>
              </div>
            </IonCol>
             <IonCol className="sideCol" size="1"></IonCol>
          </IonRow>
        </IonGrid>
                ):(
                  <div>
                    <SwipeContainer/>
                    <ExitContainer></ExitContainer>
                  </div>
                )}
        
      </IonContent>
    </IonPage>
  );
};

export default Swipe;
