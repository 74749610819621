import React, { useState, useEffect } from 'react';
import { IonButton, IonInput, IonIcon, IonFab, IonFabButton, IonThumbnail, IonFabList, IonPopover, IonContent, IonItem, IonBadge, useIonViewWillEnter } from '@ionic/react';
import './MPContainer.css';
import { Plugins, KeyboardInfo } from '@capacitor/core';
import {host} from '../App.config.json';
import {getEventDateStr} from './utils';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import {isIOS} from 'react-device-detect';
import EventContainer from './EventContainer';
import { useHistory } from 'react-router';

const { App: CapApp } = Plugins;

interface ContainerProps { }

const HelloContainer: React.FC<ContainerProps> = () => {

  const history = useHistory();

  const { Storage } = Plugins;
  const { Keyboard } = Plugins;


  const [wideScreenMode, setWideScreenMode] = useState(false);

  useIonViewWillEnter(() => {
      
    if (window.innerWidth > 800) {
      setWideScreenMode(true);
    }

    loglevelServerSend(log,{});

    const params = new URLSearchParams(document.location.search);
    const eventParam = params.get("event");
    if (eventParam != null && eventParam.trim().length > 0) {
      let searchingFor = eventParam.trim();
      
      setTimeout(() => {
        let searchField = document.getElementById("searchField") as HTMLInputElement;
        searchField.value = searchingFor;
      }, 500);
      
      findEvents(searchingFor);
    }

    CapApp.addListener('appUrlOpen', (data: any) => {
      if (data.url.indexOf("?event=") > -1) {
        let searchingFor = data.url.substring(data.url.indexOf("?event=") + 7).trim();
        
        
        setTimeout(() => {
          let searchField = document.getElementById("searchField") as HTMLInputElement;
          searchField.value = searchingFor;
        }, 500);
        
        findEvents(searchingFor);
      }
    });
      
  });

  const [foundEvents, setFoundEvents] = useState([]);
  const [searchingEvents, setSearchingEvents] = useState(false);

  const findEvents = (input: String) => {
    if (input != "   " && input.length == 0) {
      setFoundEvents([]);
      setSearchingEvents(false);
      return;
    }
    setSearchingEvents(true);
    fetch(host + "/findEvents", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: "guest", input: input})})
        .then(res => res.json())
        .then(
          (result) => {
            setFoundEvents(result.events);
            // foundEventsPart.scrollIntoView({ behavior: "smooth", block: "center" });
          },
          (error) => {
            log.error("error findEvents (" + input + "): " + error);
          }
        ); // -fetch
  }

  const goToEvent = (eventId: string) => {
    Storage.set({key:"helloGoToEvent", value: eventId});
    history.push("/SignUp");
  }

  const clearSearchField = () => {
    let searchField = document.getElementById("searchField") as HTMLInputElement;
    if (searchField) {
      searchField.value = "";
      setSearchingEvents(false);
      searchField.blur();
      Keyboard.hide().catch((e)=>{});
    }
    let mycontent = document.getElementsByClassName("mycontent");
    if (mycontent && mycontent.length > 0) {
      (mycontent[0] as HTMLIonContentElement).style.top = "0px";
      setTimeout(() => {
        (mycontent[0] as HTMLIonContentElement).style.top = "60px";
        Keyboard.hide().catch((e)=>{});
      }, 100);
    }
    // document.location.href = "/events"; // super dirty workaround
  }

  const hideKeyboard = () => {
    Keyboard.hide().catch((e)=>{});
  }

  

  return (
    <div className={"mpcontainer maincontainer" + (isIOS? " iosPaddingTop" : "")}>

      <div className="centered">
        <br/>
      <img src={"assets/icon/" + (wideScreenMode? "logo_001.png" : "logo_002.png")} className="logoBig"/>
      <div style={{paddingTop: 6, color: "#10234f", fontStyle: "oblique"}}>Match. Chat. Go together.</div>
      <span hidden={searchingEvents && !wideScreenMode}>
      <br/>
      <IonButton size="large" onClick={()=>{history.push("/SignIn")}} className="pinkLabel" fill="clear">Sign in</IonButton> &nbsp;&nbsp;&nbsp;
      <IonButton size="large" onClick={()=>{history.push("/SignUp")}} className="pinkLabel" fill="clear">Sign Up</IonButton>
      </span>
      </div>

      <br/>
      {/* <IonItem> */}
      <div className="findEventInputDiv searchBar">
      <div className="findEventClearButtonDiv" hidden={!searchingEvents}><img src="assets/icon/close.svg" className="clearEventSearchField cursorHand" onClick={()=>{clearSearchField();}}/></div>
      <IonInput placeholder="Find event" id="searchField" className="findEventInput" onIonChange={(e:any)=>findEvents((e.target as HTMLInputElement).value)} onIonFocus={()=>{setSearchingEvents(true);} }></IonInput>
      </div>
      {/* </IonItem> */}
      <br/><br/>
      {/* <div style={{ float:"left", clear: "both" }}
             ref={(el) => { foundEventsPart = el; }}>
        </div> */}
      {foundEvents.map((event: any) => {
        
        event.going = false;
        
        return (
          <EventContainer key={event.id} go={goToEvent} eventId={event.id} eventName={event.eventName} eventAbout={event.about} eventLocation={event.location} eventPicture={event.pictures[0]}
          eventStart={event.start} eventTypeStr={event.eventTypeStr} statsGoingMen={event.statsGoingMen? event.statsGoingMen : -1} statsGoingWomen={event.statsGoingWomen? event.statsGoingWomen : -1} statsGoingAgesRange={event.statsGoingAgesRange? event.statsGoingAgesRange : ""} organizerName={event.organizerName} wideScreenMode={wideScreenMode}></EventContainer>
        // <div className="foundEvent" onTouchStart={()=>{hideKeyboard();}}>
        //   <div className="leftalign-nofloat line-height-big organizerAddedEvent">
        //   <b>{event.organizerName}</b> has added {(['a','i','o','u','e'].indexOf(event.eventTypeStr.toLowerCase()[0]) > -1? "an " : "a ") + event.eventTypeStr}
        //   </div>
        //   <div className="eventImgDiv">
        //   <img src={"https://gowith.s3.us-east-2.amazonaws.com/events/" + event.id + "_" + event.pictures[0] + ".jpeg"} className="profilePhoto"/>
        //   <div className="eventBadges">
        //     <IonBadge slot="end" color="primary"><img src="assets/icon/people.svg" className="verticalMiddle" /> {event.statsGoing}</IonBadge>
        //     {/* <br/>
        //     <IonBadge slot="end" color="danger">Matched: {event.statsMatched}</IonBadge>
        //     <br/>
        //     <IonBadge slot="end" color="light">Swiped: {event.statsSwiped}</IonBadge> */}

        //   </div>
        //   </div>

        // <IonButton className="eventAttendingButton pinkButton eventAttendingButtonGoing" onClick={()=>{goToEvent(event.id); }}>GO</IonButton>
        //   <div className="leftalign-nofloat line-height-big stretchDiv">
        //     <span className="pinkLabel smallLabel">{getEventDateStr(event.start)}</span>
        //     <br/>
        //     <b>{event.eventName}</b>
        //     <br/>
        //     <span className="smallGreyLabel">{event.location}</span>
        //   </div>
        //     <div className="line-above leftalign-nofloat eventDesc">
        //       <div className={"mediumGreyLabel" + (/[א-ת].+/.test(event.about)? " rtl":"")}>{event.about}</div>
        //     </div>
        //   <br/>
        // </div>
        )
      })}
    <br/><br/>{wideScreenMode? (<br/>):""}
    <div className="centered" hidden={searchingEvents} style={{fontSize: "larger", lineHeight: "30pt"}}>
    To start, please find the event you've been invited to,  
    {wideScreenMode? (<br/>):" "}
     then click the <span className="pinkButton" style={{padding: "6px", color: "white"}}>GO</span> button.
    </div>
    
    </div>
  );
};

export default HelloContainer;
