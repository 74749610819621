import { IonContent, IonHeader, IonPage, IonToolbar, IonButton, IonIcon, IonGrid, IonRow, IonCol, IonFooter, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
//import ExploreContainer from '../components/ExploreContainer';
import ProfileContainer from '../components/ProfileContainer';
import ExitContainer from '../components/ExitContainer';
import WebFooterContainer from '../components/WebFooter';
import {isIOS, isAndroid, isMobileSafari, isChrome, isChromium, isEdge, isEdgeChromium, isFirefox, isOpera, isSafari} from 'react-device-detect';
import MobileWebFooterContainer from '../components/MobileWebFooter';
import {isWebApp as isWebApp} from '../App.config.json';
import { useHistory } from "react-router-dom";

const Home: React.FC = () => {

  const [wideScreenMode, setWideScreenMode] = useState(window.innerWidth > 800);

  const [unreadMessages, setUnreadMessages] = useState(false);
  const unreadMsgs = (unread: boolean) => {
    setUnreadMessages(unread);
  }

  const history = useHistory();
  
  useIonViewWillEnter(() => {
    
    (window as any).unreadMsgsListeners.push(unreadMsgs);
    
  });

  return (
    <IonPage>
      <IonHeader >
        <IonToolbar>
        <img className="topicon" height={36} src={"assets/icon/" + (wideScreenMode? "logo_004.png" : "logo-notext-s.png")} style={{float: "left", paddingLeft: wideScreenMode? "4%" : 12, marginTop: 2, marginRight: wideScreenMode && isWebApp == "true"? -132:0}} />
            <IonButton>
              {wideScreenMode? (
                <img className="topicon topicon-web" src={"assets/icon/profile-on-web.svg"} />
              ) : (
              <IonIcon className="topicon" src={"assets/icon/profile-on.svg"} />
              )}
            </IonButton>
            <IonButton onClick={()=>{history.push("/Events")}}>
            {wideScreenMode? (
                <img className="topicon topicon-web" src={"assets/icon/events-web.svg"} />
              ) : (
                <IonIcon className="topicon" src="assets/icon/events.svg" />
              )}
              </IonButton>
            <IonButton onClick={()=>{history.push("/Chats")}}>
            {wideScreenMode? (
                <img className="topicon topicon-web" src={"assets/icon/chat-web.svg"} />
              ) : (
                <IonIcon slot="icon-only" src={unreadMessages? "assets/icon/chat-unread.svg" : "assets/icon/chat.svg"} />
              )}
            </IonButton>
        </IonToolbar>
    </IonHeader>
      <IonContent>
        {wideScreenMode? (
          <span>
        <IonGrid>
          <IonRow>
          <IonCol className="sideCol" size="1"></IonCol>
            <IonCol className="mainCol" size="10">
              <div className="mainDiv">
                <ProfileContainer/>
                <ExitContainer></ExitContainer>
              </div>
            </IonCol>
             <IonCol className="sideCol" size="1"></IonCol>
          </IonRow>
        </IonGrid>
        {isWebApp == "true"? (
        <WebFooterContainer></WebFooterContainer>
        ): ""}
          </span>
                ):(
                  <div>
                  <ProfileContainer/>
                  <ExitContainer></ExitContainer>
                  {(isMobileSafari || isChrome || isChromium || isEdge || isEdgeChromium || isFirefox || isOpera || isSafari) && (isIOS || isAndroid)? (
          <MobileWebFooterContainer></MobileWebFooterContainer>
          ):""}
                </div>
                )}
      </IonContent>
      
    </IonPage>
  );
};

export default Home;
