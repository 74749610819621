import React, { useEffect, useState } from 'react';
import { IonButton, IonInput, IonIcon, IonFab, IonFabButton, IonThumbnail, IonFabList, IonPopover, IonContent, IonItem, IonBadge, IonHeader, IonToolbar, IonButtons, IonTitle, useIonViewWillEnter } from '@ionic/react';
import './MPContainer.css';
import { Plugins, KeyboardInfo } from '@capacitor/core';
import {host, webAppURL, isWebApp} from '../App.config.json';
import {getEventDateStr} from './utils';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import {isIOS} from 'react-device-detect';
import EventContainer from './EventContainer';
import PaymentsContainer from './PaymentsContainer';
import ReportContainer from './ReportContainer';
import ShareContainer from './ShareContainer';
import { useHistory } from "react-router-dom";


interface ContainerProps { }

let userDetails : any;
// let foundEventsPart : any;
let goToEventId : String;
// let showHostingEvent: Boolean;
let hasHostingEvents = false;

const EventsContainer: React.FC<ContainerProps> = () => {

  const history = useHistory();

  const { Storage } = Plugins;
  const { Keyboard } = Plugins;

  const [showHostingEvent, setShowHostingEvent] = useState(false);

  const [organizer, setOrganizer] = useState(false);
  
  const [hostedEvents, setHostedEvents] = useState([]);
  const [goingEvents, setGoingEvents] = useState([]);
  const [noEvents, setNoEvents] = useState(false);
  

  const [wideScreenMode, setWideScreenMode] = useState(false);
  
  useIonViewWillEnter(async () => {

    window.scrollTo({top: 0, behavior: "smooth"});
  
    if (window.innerWidth > 800) {
      setWideScreenMode(true);
    }
    
    let userDetailsStr = await Storage.get({key:"userDetails"});
    userDetails = JSON.parse(userDetailsStr.value + "");

    loglevelServerSend(log,{user: userDetails});

    if (userDetails.userType == 2) {
      setOrganizer(true);
    }

    // +Rate app
    // Storage.get({key:'lastScreen'}).then((data: any) => {
    //   if ("IMIN" == data.value) {
    //     Storage.remove({key: "lastScreen"});
    //     Storage.get({key:'rateApp'}).then((data: any) => {
    //       if ("NO" != data.value) {
    //         setShowAlertRate(true);
    //       }
    //     });
    //   }
    // });
    // -Rate app

    if (userDetails.login && userDetails.login.token)
    fetch(host + "/hostedEvents", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "fail" && result.reason == "token") history.push("/signout");
            if (result.events) {
              setHostedEvents(result.events);
              hasHostingEvents = result.events.length > 0;
            }
          },
          (error) => {
            log.error("error hostedEvents: " + error);
          }
        ); // -fetch

    let helloGoToEvent = await Storage.get({key:"helloGoToEvent"});
    if (helloGoToEvent && helloGoToEvent.value) {
      goToEvent(helloGoToEvent.value);
      Storage.remove({key:'helloGoToEvent'});
    }
    getGoingEvents();

  });
  
  const getGoingEvents = () => {
    if (userDetails.login && userDetails.login.token)
    fetch(host + "/goingEvents", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.events && result.events.length > 0) {
              setGoingEvents  (result.events);
              setNoEvents(false);
              if (result.events.length == 1 && !hasHostingEvents) {
                showGoingEvent(-1, result.events[0]);
              }
            } else {
              setGoingEvents([]);
              setNoEvents(true);
            }
            
            // open the newly added event
            if (goToEventId != "") {
              let idx = -1;
              for (let i = 0; i < result.events.length; i++) {
                if ((result.events[i] as any).id == goToEventId) {
                  clearSearchField();
                  showGoingEvent(idx, result.events[i]);
                  break;
                }
              }
              goToEventId = "";
            }
          
          },
          (error) => {
            log.error("error getGoingEvents: " + error);
          }
        ); // -fetch
  }

  

  const [foundEvents, setFoundEvents] = useState([]);
  const [searchingEvents, setSearchingEvents] = useState(false);

  const findEvents = (input: String) => {
    if (input.length <= 2) {
      setFoundEvents([]);
      // setSearchingEvents(false);
      return;
    }
    setSearchingEvents(true);
    fetch(host + "/findEvents", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, input: input})})
        .then(res => res.json())
        .then(
          (result) => {
            setFoundEvents(result.events);
            // foundEventsPart.scrollIntoView({ behavior: "smooth", block: "center" });
          },
          (error) => {
            log.error("error findEvents (" + input + "): " + error);
          }
        ); // -fetch
  }

  const goToEvent = (eventId: String) => {
    fetch(host + "/goToEvent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: eventId})})
        .then(res => res.json())
        .then(
          (result) => {
            userDetails = result.userDetails;
            const userDetailsStringified = JSON.stringify(result.userDetails);
            Storage.set({key:"userDetails", value: userDetailsStringified});
            goToEventId = eventId;
            getGoingEvents();
          },
          (error) => {
            log.error("error goToEvent (" + eventId + "): " + error);
          }
        ); // -fetch
  }


  // going event popover
  const [goingEventOrganizerName, setGoingEventOrganizerName] = useState("");
  const [goingEventEventTypeStr, setGoingEventEventTypeStr] = useState("");
  const [goingEventId, setGoingEventId] = useState("");
  const [goingEventPhoto, setGoingEventPhoto] = useState("");
  const [goingEventStatsGoing, setGoingEventStatsGoing] = useState("");
  const [goingEventStatsGoingMen, setGoingEventStatsGoingMen] = useState("");
  const [goingEventStatsGoingWomen, setGoingEventStatsGoingWomen] = useState("");
  const [goingEventStatsGoingAgesRange, setGoingEventStatsGoingAgesRange] = useState("");
  const [goingEventStatsDays, setGoingEventStatsDays] = useState<number>(0);
  const [goingEventUserApproved, setGoingEventUserApproved] = useState(false);
  const [goingEventStart, setGoingEventStart] = useState("");
  const [goingEventEventName, setGoingEventEventName] = useState("");
  const [goingEventLocation, setGoingEventLocation] = useState("");
  const [goingEventAbout, setGoingEventAbout] = useState("");
  const [goingEventSeeMatches, setGoingEventSeeMatches] = useState(false);
  const [goingEventModerated, setGoingEventModerated] = useState(false);
  const [goingEventDisableChat, setGoingEventDisableChat] = useState(false);
  const [goingEventSilentMatches, setGoingEventSilentMatches] = useState(false);
  
  const [hostedEventStatsClosed, setHostedEventStatsClosed] = useState("");
  const [hostedEventStatsMatched, setHostedEventStatsMatched] = useState("");
  const [hostedEventStatsSwipes, setHostedEventStatsSwipes] = useState("");
  const [hostedEventStatsMessages, setHostedEventStatsMessages] = useState("");
  const [hostedEventStatsConversations, setHostedEventStatsConversations] = useState("");
  const [hostedEventApproved, setHostedEventApproved] = useState("");
  const [hostedEventCancelledOrActive, setHostedEventCancelledOrActive] = useState("");
  const [hostedEventSubEndDate, setHostedEventSubEndDate] = useState("");
  const [hostedEventRecurring, setHostedEventRecurring] = useState("");
  const [hostedEventTicketsSold, setHostedEventTicketsSold] = useState("");
  

  const [showEventPopover, setShowEventPopover] = useState(false);
  const [showPaymntPopover, setShowPaymntPopover] = useState(false);

  const showEvent = (eventId: string) => {
    let event:any = null;
    for (let i = 0; i < goingEvents.length; i++) {
      event = goingEvents[i];
      if (event.id == eventId) {
        showGoingEvent(i);
        break;
      }
      
    }
  }

  const showGoingEvent = (idx: number, ge?: any, hostedEvent?: boolean) => {
    setShowEventPopover(true);
    setShowHostingEvent(hostedEvent? true : false);
    const event = hostedEvent? hostedEvents[idx] as any : ge? ge : goingEvents[idx] as any;
    setGoingEventOrganizerName(event.organizerName);
    setGoingEventEventTypeStr(event.eventTypeStr || event.eventType || "");
    setGoingEventId(event.id);
    setGoingEventPhoto(event.pictures[0]);
    setGoingEventStart(event.start);
    setGoingEventEventName(event.eventName);
    setGoingEventLocation(event.location);
    setGoingEventAbout(event.about);
    setGoingEventStatsGoing(event.statsGoing);
    
    setGoingEventSeeMatches(event.seeMatches);
    setGoingEventModerated(event.moderateUsers);
    setGoingEventSilentMatches(event.silentMatches);
    setGoingEventDisableChat(event.disableChat);
    if (event.moderateUsers && event.organizerUserId != userDetails.id) {
      
      for (let i = 0; i < userDetails.events.length; i++) {
        if (userDetails.events[i].id == event.id) {
          if (!userDetails.events[i].approved) {
            // check on server if we're approved
            fetch(host + "/getEventApproval", {
              method: 'POST', headers: {
                'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: event.id})})
                .then(res => res.json())
                .then(
                  (result) => {
                    if (result.approved) {
                      // update userDetails
                      userDetails.events[i].approved = true;
                      Storage.set({key:"userDetails", value: JSON.stringify(userDetails)});
                      setGoingEventUserApproved(true);
                    } else {
                      setGoingEventUserApproved(false);
                    }
                  });
          } else {
            setGoingEventUserApproved(true);
          }
          break;
        }
      }
    } else {
      setGoingEventUserApproved(true);
    }
    setHostedEventApproved(event.approved);

    let diff = Math.abs(new Date(event.start).getTime() - new Date().getTime());
    let diffDays = Math.ceil(diff / (1000 * 3600 * 24)) - 1; 
    setGoingEventStatsDays(diffDays);
    fetch(host + "/getEvent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: event.id})})
        .then(res => res.json())
        .then(
          (result) => {
            var event = result.eventDetails;
            if (event) {
              let menGoing = result.stats.men? result.stats.men : "";
              let womenGoing = result.stats.women? result.stats.women : "";
              
              setGoingEventStatsGoingMen(menGoing);
              setGoingEventStatsGoingWomen(womenGoing);
              if (result.stats.youngest && result.stats.oldest) {
                setGoingEventStatsGoingAgesRange(result.stats.youngest + "-" + result.stats.oldest);
              } else {
                setGoingEventStatsGoingAgesRange("");
              }
              setGoingEventStatsGoing(menGoing + womenGoing);

              if ((hostedEvent || showHostingEvent) && result.organizerStats) {
                setHostedEventStatsClosed(result.organizerStats.closed);
                setHostedEventStatsMatched(result.organizerStats.matched);
                setHostedEventStatsSwipes(result.organizerStats.swipes);
                setHostedEventApproved(event.approved);
                setHostedEventStatsMessages(result.organizerStats.messages);
                setHostedEventStatsConversations(result.organizerStats.conversations);
                setHostedEventCancelledOrActive(event.billing && event.billing.stripe && !event.billing.stripe.cancelled? "active" : "cancelled");
                setHostedEventSubEndDate(event.billing && event.billing.stripe && event.billing.stripe.subEndDate? event.billing.stripe.subEndDate : "");
                setHostedEventRecurring(event.recurring);
                var ticketsSold = 0;
                if (event.billing && event.billing.userTickets) {
                  for (let i = 0; i < event.billing.userTickets.length; i++) {
                    ticketsSold += event.billing.userTickets[i].tickets;
                  }
                }
                setHostedEventTicketsSold(ticketsSold + "");
              }

            }
          },
          (error) => {
            log.error("error getEvent (" + event.id + "): " + error);
          }
        ); // -fetch
  }

  const cantGoToEvent = (eventId: string) => {
    if (!window.confirm("Remove event?")) return;
    fetch(host + "/cantGoToEvent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: eventId})})
        .then(res => res.json())
        .then(
          (result) => {
            userDetails = result.userDetails;
            const userDetailsStringified = JSON.stringify(result.userDetails);
            Storage.set({key:"userDetails", value: userDetailsStringified});
            getGoingEvents();
          },
          (error) => {
            log.error("error cantGoToEvent (" + eventId + "): " + error);
          }
        ); // -fetch
    setShowEventPopover(false);
  }

  const swipeForEvent = (eventId: string) => {
    
    setShowEventPopover(false);
    
    if (!goingEventUserApproved) {
      alert("Event organizer has not yet approved your participation. You will get a notification when this happens.");
      return;
    }
    Storage.set({key: "swipingForEventId", value: eventId});
    if (!userDetails.pictures || userDetails.pictures.length == 0) {
      alert("Please add at least 1 photo before starting");
      history.push("/Setup");
      return;
    }
    if (userDetails.dob == undefined || userDetails.sex == undefined || userDetails.iiGender == undefined || userDetails.iiMinAge == undefined || userDetails.iiMaxAge == undefined) {
      alert("Please complete your profile before starting");
      history.push("/Setup");
      return;
    }
    history.push("/swipe");
  }

  const clearSearchField = () => {
    let searchField = document.getElementById("searchField") as HTMLInputElement;
    if (searchField) {
      searchField.value = "";
      setSearchingEvents(false);
      searchField.blur();
      Keyboard.hide().catch((e)=>{});
    }
    let mycontent = document.getElementsByClassName("mycontent");
    if (mycontent) {
      (mycontent[0] as HTMLIonContentElement).style.top = "0px";
      setTimeout(() => {
        (mycontent[0] as HTMLIonContentElement).style.top = "0px";
        Keyboard.hide().catch((e)=>{});
      }, 100);
    }
    // document.location.href = "/events"; // super dirty workaround
  }

  const hideKeyboard = () => {
    Keyboard.hide().catch((e)=>{});
  }

  const pay = () => {
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (new Date(goingEventStart) < tomorrow) {
      alert("Can't pay for event whos start date is in the past");
    } else {
      setShowPaymntPopover(true);
    }
  }

  const editEvent = (eventId?: string) => {
    if (eventId) {
      Storage.set({key:"editEventId", value: eventId});
    } else {
      Storage.set({key:"editEventId", value: "new"});
    }
    history.push("/EditEvent");
  }

  return (
    <div className={"mpcontainer maincontainer" + (isIOS? " " : "")}>
      <IonPopover
        isOpen={showPaymntPopover}
        className={wideScreenMode? 'genericPopover genericPopoverWide' : 'genericPopover'}
        onDidDismiss={() => setShowPaymntPopover(false)} 
        >
          <div>
            <PaymentsContainer paytype="eventFee" eventId={goingEventId} done={function () {document.location.reload()}}></PaymentsContainer>
          </div>
        </IonPopover>
      <IonPopover
        isOpen={showEventPopover}
        className={wideScreenMode? 'genericPopover genericPopoverWide' : 'genericPopover'}
        onDidDismiss={() => setShowEventPopover(false)} 
        >
          <IonHeader translucent>
            <IonToolbar className={"eventPopupToolbar" + (isIOS? "Ios" : "") + (goingEventOrganizerName.length + goingEventEventTypeStr.length > 17? " eventPopupToolbarLong":"")}>
              <IonTitle>
              <div className={"leftalign-nofloat line-height-big organizerAddedEvent organizerAddedEventNegativeMarginRight" + (goingEventOrganizerName.length + goingEventEventTypeStr.length > 17? " organizerAddedEventLong":"")}>
              <b>{goingEventOrganizerName}</b> is hosting {(['a','i','o','u','e'].indexOf(goingEventEventTypeStr.toLowerCase()[0]) > -1? "an " : "a ") + goingEventEventTypeStr}
              </div>
              </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={()=>{setShowEventPopover(false);}} fill="clear" color="primary">Close &nbsp;<img src="assets/icon/close-blue.svg" className="verticalMiddle" /></IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          
          <IonContent scrollY={true}>
          <div className={wideScreenMode && isWebApp == "true"? "foundEventPop foundEventPopWide" : "foundEvent" + (isIOS? "Ios":"")}>
          
          <div className="eventImgDiv">
          <img src={!goingEventPhoto || goingEventPhoto == "" || goingEventPhoto == "undefined"? "assets/icon/defaulteventphoto.svg" : "https://gowith.s3.us-east-2.amazonaws.com/events/" + goingEventId + "_" + goingEventPhoto + ".jpeg"} className="profilePhoto"/>
          <div className="eventBadges">
          &nbsp; 
          </div>
          </div>
          {showHostingEvent? (
            <IonButton className="eventAttendingButton cantGoButton" color="primary" onClick={()=>{setShowEventPopover(false); editEvent(goingEventId)}} >Edit</IonButton>
            ): (
          <IonButton className="eventAttendingButton cantGoButton" color="medium" onClick={()=>{cantGoToEvent(goingEventId)}}>Can't go?</IonButton>
            )}
          <div className="leftalign-nofloat line-height-big stretchDiv">
            <span className="pinkLabel smallLabel">{getEventDateStr(goingEventStart)}</span>
            <br/>
            <b>{goingEventEventName}</b>
            <br/>
            <span className="smallGreyLabel">{goingEventLocation}</span>
          </div>
          <br/>
          <div className="leftalign-nofloat line-height-big">
            <IonBadge slot="end" color="medium"><img src="assets/icon/time.svg" className="verticalMiddle" /> {goingEventStatsDays > 2? "In " + goingEventStatsDays + " days" : goingEventStatsDays == 1? "Tomorrow" : goingEventStatsDays == 0? "Today" : ""}</IonBadge>
            &nbsp; 
            {hostedEventApproved == "yes"? (<span>
            <IonBadge slot="end" color="medium" hidden={goingEventStatsGoingMen == ""}><img src="assets/icon/men.svg" className="verticalMiddle" /> {goingEventStatsGoingMen}</IonBadge>
             &nbsp; 
            <IonBadge slot="end" color="medium" hidden={goingEventStatsGoingWomen == ""}><img src="assets/icon/women.svg" className="verticalMiddle" /> {goingEventStatsGoingWomen}</IonBadge>
            &nbsp; 
            <IonBadge slot="end" color="medium" hidden={goingEventStatsGoingAgesRange == ""}><img src="assets/icon/ages.svg" className="verticalMiddle" /> {goingEventStatsGoingAgesRange}</IonBadge>
            </span>):("")}
            {showHostingEvent? (
              <div>
                {hostedEventApproved == "yes"? (<span>
                <IonBadge slot="end" color="medium"><img src="assets/icon/people.svg" className="verticalMiddle" /> {goingEventStatsGoing} People Going</IonBadge>
                &nbsp; 
                <br/><br/><b>Confidential data:</b><br/><br/>
                <IonBadge slot="end" color="light" className="iconlessBadge">Going Together: {hostedEventStatsClosed}</IonBadge>
                &nbsp; 
                <IonBadge slot="end" color="light" className="iconlessBadge">Matched: {hostedEventStatsMatched}</IonBadge>
                &nbsp; 
                <IonBadge slot="end" color="light" className="iconlessBadge">Conversations: {hostedEventStatsConversations}</IonBadge> 
                &nbsp; 
                <IonBadge slot="end" color="light" className="iconlessBadge">Messages: {hostedEventStatsMessages}</IonBadge> 
                &nbsp; 
                <IonBadge slot="end" color="light" className="iconlessBadge">Swipes: {hostedEventStatsSwipes}</IonBadge> 
                &nbsp; </span>):("")}
                <br/>
                <div style={{textAlign: "center"}}>
                  <br/>
                  {/* <IonBadge slot="end" color="light" className="iconlessBadge">Tickets sold: {hostedEventTicketsSold}</IonBadge> */}
                  <IonBadge slot="end" color="light" className="" hidden={hostedEventApproved != "yes"}>
                  [<a target="_blank" href="https://services.whotogowith.com/adminlogin">Event admin area</a>]
                  </IonBadge>
                  <br/><br/>
                  <IonBadge slot="end" color="success" hidden={hostedEventApproved != "yes"} style={{width: "50%", padding: "14px"}}>
                    Event approved
                  </IonBadge>
                  <div hidden={hostedEventCancelledOrActive != "active" || +(new Date(hostedEventSubEndDate)) > +(new Date(goingEventStart))}>
                    Next payment on {hostedEventSubEndDate.substring(0, 10)}
                  </div>
                  <IonBadge slot="end" color="danger" hidden={hostedEventApproved != "no"} style={{width: "50%", padding: "14px"}}>
                    Event not approved
                  </IonBadge>
                  <IonBadge slot="end" color="warning" style={{cursor: "pointer", padding: "14px"}} onClick={()=>{pay()}} hidden={hostedEventApproved != "pendingpay"}>
                    Pending payment (click to pay)
                  </IonBadge>
                  <IonBadge slot="end" color="warning" hidden={hostedEventApproved != "pending"} style={{width: "50%", padding: "14px"}}>
                    Pending approval
                  </IonBadge>
                </div>
                <br/><br/>
                Event link: <a target="_blank" href={webAppURL + "hello?event=" + goingEventEventName}>{webAppURL.replace("http://", "") + "hello?event=" + goingEventEventName}</a>
              </div> ): ("")}
          </div>
          <br/>
          <div hidden={!goingEventSeeMatches} className="mediumGreyLabel" style={{"position": "relative", "width": "80%", "left": "10%"}}>
          🕵 In this event, the organizer will be able to see who liked and matched with who.<br/><br/>
          </div>
          <div hidden={!goingEventModerated || goingEventUserApproved} className="mediumGreyLabel" style={{"position": "relative", "width": "80%", "left": "10%"}}>
          ✓ The event is moderated. The organizer has to approve you before you can see who's going.<br/><br/>
          </div>
          <div hidden={!goingEventSilentMatches} className="mediumGreyLabel" style={{"position": "relative", "width": "80%", "left": "10%"}}>
          👁️ You will not know whom you matched with. The organizer will.<br/><br/>
          </div>
          <div hidden={!goingEventDisableChat} className="mediumGreyLabel" style={{"position": "relative", "width": "80%", "left": "10%"}}>
          💬 Chat is disabled in this event.
          </div>
          <br/>
            <IonButton expand={"full"} className="pinkButton" onClick={()=>{swipeForEvent(goingEventId)}}>
            {showHostingEvent? (
            "See who's going"
          ): goingEventUserApproved? ("Discover Who to go with") : "Pending approval"}
              </IonButton>
          
          <div className="leftalign-nofloat eventDesc">
            <div className={"mediumGreyLabel aboutEvent" + (/[א-ת].+/.test(goingEventAbout)? " rtl":"")}><span className="preLine">{goingEventAbout}</span></div>
          </div>
          <br/>{showHostingEvent? "" : (
          <span>
            <ShareContainer id={goingEventId} eventName={goingEventEventName}></ShareContainer>
            <ReportContainer type="event" id={goingEventId}></ReportContainer>
          </span>
          )}
        </div>
        </IonContent>
        </IonPopover>


        <div id="eventsHosting" hidden={searchingEvents}>
      <br/>
      {organizer? (
        <div>
          {/* <IonFab vertical="top" horizontal="end" slot="fixed" className="editiconfab">
            <IonFabButton size="small" className="pinkFabButton">
              <IonIcon  className="editicon" icon="assets/icon/plus.svg" />
            </IonFabButton>
            <IonFabList side="start">
              <IonButton className="pinkButton" href="EditEvent?id=new">Create an event</IonButton>
            </IonFabList>
          </IonFab> */}
          {isWebApp != "true" && isIOS? "" : (<span>
          <IonButton className="pinkButton" onClick={function (){if (isWebApp != "true" && isIOS) {alert("Event creation and management can only be done via the WhoToGoWith website.")} else {editEvent()}}}>Create an event</IonButton>
          <br/><br/>
          </span>
          ) }
          {hostedEvents.length == 0? (
                <div>
                  
                </div>
              ):(
                <div className="leftalign-nofloat">
                  <b>Events you're hosting</b>
                  <br/><br/>
                  <div className="myEvents">
                    {hostedEvents.map((event: any, idx: number) =>
                      <div className="myEvent" key={idx}>
                        <IonThumbnail className="myEventPhoto">
                        {/* <a href={"EditEvent?id=" + event.id}> */}
                        <a onClick={()=>{showGoingEvent(idx, null, true);}} className="cursorHand">
                        <img src={!event.pictures[0] || event.pictures[0] == "" || event.pictures[0] == "undefined"? "assets/icon/defaulteventphoto.svg" : "https://gowith.s3.us-east-2.amazonaws.com/events/" + event.id + "_" + event.pictures[0] + ".jpeg"} className={"myEventPhoto" + (getEventDateStr(event.start, true, true) == "-past-"? " expiredEventPhoto": event.approved != "yes"? " pendingEventPhoto":"")}/><br/>
                        <div className={"myEventTltle" + (event.approved != "yes"? " pendingEventTitle":"")}>{event.eventName}</div>
                        <div className={"eventDate" + (getEventDateStr(event.start, true, true) == "-past-"? " expiredEventTltle":"")}>{getEventDateStr(event.start, true, true)}</div></a></IonThumbnail>
                      </div>
                    )}
                  </div>
                </div>
              )}
          
          
          

      </div>
      ):(
        ""
      )}
    
      <br/></div>
      <div id="eventsGoing" hidden={searchingEvents}>
      
      {noEvents && goingEvents.length == 0? (
                <div>
                  
                </div>
              ):(
                <div className="leftalign-nofloat">
                  <div className="leftalign">
        <b>Events you're going to</b>
      </div>

      <br/><br/><br/>
                  <div className="myEvents">
                    {goingEvents.map((event: any, idx: number) =>
                      <div className="myEvent" key={idx}>
                        <IonThumbnail className="myEventPhoto">
                        <a onClick={()=>{showGoingEvent(idx);}} className="cursorHand">
                        <img src={!event.pictures[0] || event.pictures[0] == "" || event.pictures[0] == "undefined"? "assets/icon/defaulteventphoto.svg" : "https://gowith.s3.us-east-2.amazonaws.com/events/" + event.id + "_" + event.pictures[0] + ".jpeg"} className="myEventPhoto"/><br/>
                        <div className="myEventTltle">{event.eventName}</div>
                        <div className="eventDate">{getEventDateStr(event.start, true)}</div>
                        </a>
                        </IonThumbnail>
                        </div>
                    )}
                  </div>
                </div>
              )}
      
      <br/><br/><br/></div><IonItem className="searchBar">
      <div hidden={!searchingEvents}><img src="assets/icon/close.svg" className="clearEventSearchField cursorHand" onClick={()=>{clearSearchField();}}/></div>
      <IonInput placeholder="Find event" id="searchField" onIonChange={(e:any)=>findEvents((e.target as HTMLInputElement).value)} onIonFocus={()=>{setSearchingEvents(true);} }></IonInput>
      </IonItem>
      <br/><br/>
      {/* <div style={{ float:"left", clear: "both" }}
             ref={(el) => { foundEventsPart = el; }}>
        </div> */}
      {foundEvents.map((event: any) => {
        
        event.going = false;
        for (let i = 0; i < goingEvents.length; i++) {
          let goingEvent = goingEvents[i] as any;
          if (goingEvent.id == event.id) {
            event.going = true;
            break;
          }
        }
        
        return (
          <EventContainer key={event.id} go={goToEvent} show={showEvent} eventId={event.id} eventName={event.eventName} eventAbout={event.about} eventLocation={event.location} eventPicture={event.pictures[0]}
          eventStart={event.start} eventTypeStr={event.eventTypeStr} statsGoingMen={event.statsGoingMen? event.statsGoingMen : -1} statsGoingWomen={event.statsGoingWomen? event.statsGoingWomen : -1} statsGoingAgesRange={event.statsGoingAgesRange} organizerName={event.organizerName} eventGoing={event.going} wideScreenMode={wideScreenMode}></EventContainer>
        // <div className="foundEvent" onTouchStart={()=>{hideKeyboard();}}>
        //   <div className="leftalign-nofloat line-height-big organizerAddedEvent">
        //   <b>{event.organizerName}</b> has added {(['a','i','o','u','e'].indexOf(event.eventTypeStr.toLowerCase()[0]) > -1? "an " : "a ") + event.eventTypeStr}
        //   </div>
        //   <div className="eventImgDiv">
        //   <img src={"https://gowith.s3.us-east-2.amazonaws.com/events/" + event.id + "_" + event.pictures[0] + ".jpeg"} className="profilePhoto"/>
        //   <div className="eventBadges">
        //     <IonBadge slot="end" color="primary"><img src="assets/icon/people.svg" className="verticalMiddle" /> {event.statsGoing}</IonBadge>
        //     {/* <br/>
        //     <IonBadge slot="end" color="danger">Matched: {event.statsMatched}</IonBadge>
        //     <br/>
        //     <IonBadge slot="end" color="light">Swiped: {event.statsSwiped}</IonBadge> */}

        //   </div>
        //   </div>

        // <IonButton className={event.going? "eventAttendingButton greenButton eventAttendingButtonGoing" : "eventAttendingButton pinkButton"} onClick={()=>{goToEvent(event.id); }} disabled={event.going}>{event.going? "GOING" : "GO"}</IonButton>
        //   <div className="leftalign-nofloat line-height-big stretchDiv">
        //     <span className="pinkLabel smallLabel">{getEventDateStr(event.start)}</span>
        //     <br/>
        //     <b>{event.eventName}</b>
        //     <br/>
        //     <span className="smallGreyLabel">{event.location}</span>
        //   </div>
        //     <div className="line-above leftalign-nofloat eventDesc">
        //       <div className={"mediumGreyLabel" + (/[א-ת].+/.test(event.about)? " rtl":"")}>{event.about}</div>
        //     </div>
        //   <br/>
        // </div>
        )
      })}
    <br/><br/><br/>
    
    </div>
  );
};

export default EventsContainer;
