import React, { useState, useEffect } from "react";
import { Plugins } from "@capacitor/core";
import { IonPopover, IonItem, IonButton, IonReorderGroup, IonReorder, IonList, IonLoading, IonLabel, IonToggle, useIonViewWillEnter } from "@ionic/react";
import {host, isWebApp} from '../App.config.json';
import {isMobile, isBrowser, isFirefox, isChrome, isChromium, isEdge, isEdgeChromium, isOpera, isSafari, isMobileSafari, isAndroid, isIOS} from 'react-device-detect';
import log from 'loglevel';
import {loglevelServerSend} from './utils';



// stripe
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import './CardSectionStyles.css';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { type } from "os";


let userDetails : any;

type Props = {
    eventId: String,
    done?: any,
    paytype: String,
    price?: number
}

const stripePromise = loadStripe("pk_live_51IqbbgDpSZnTAKu0QKO8rAx4TnhV2R5Hca2ubcU01l7V9fMeHuNjZuOBRoT9TtzpNCpej2VogjzPjGIOkQgnRIH600ta2NWCwP");
let card : any;
let stripe : any;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PaymentsContainer: React.FC<Props> = ({eventId, done = ()=>{}, price = 0, paytype}) => {
    
  const { Storage } = Plugins;
  const [alreadyClicked, setAlreadyClicked] = useState(false);
  const [howManyTickets, setHowManyTickets] = useState(1);

  const setPayIntent = (howMany: number) => {
    fetch(host + '/buyStripeIntent', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: userDetails.login.token,
        price: price * howMany,
        eventId: eventId
      })
    })
    .then(res => {
      return res.json();
    })
    .then(data => {
      setClientSecret(data.clientSecret);
    });
  }

  useIonViewWillEnter(() => {
    (async () => {
      let userDetailsStr = await Storage.get({key:"userDetails"});
      userDetails = JSON.parse(userDetailsStr.value + "");

      // loglevelServerSend(log,{user: userDetails});

      if (isWebApp != "true" && isIOS) {
        if (paytype == "eventFee") {
          alert("Event setup payments can only be done on WhoToGoWith.com website");
          window.open("https://app.whotogowith.com/");
        } else if (paytype == "ticket") {
          alert("Tickets purchase can only be done on WhoToGoWith.com website");
          window.open("https://app.whotogowith.com/");
        }
        return;
      }
      if (paytype != "eventFee"){
        setPayIntent(1);
      }
    })()
  });

  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    // if (paytype == "eventFee")
    setTimeout(() => {
      stripePromise.then((stripe1)=>{
        if (stripe1 && document.getElementById("card-element")) {
          var elements = stripe1.elements();
          card = elements.create('card', CARD_ELEMENT_OPTIONS);
          card.mount('#card-element');
          stripe = stripe1;
        }
      });  
    }, 1000);
    
  });

  const pay = async () => {
    if (alreadyClicked) {
      console.log("prevented subsequent click");
      return;
    }
    setAlreadyClicked(true);

    if (paytype == "eventFee") {
      var priceId = "price_1JUaQ5DpSZnTAKu08dOH0zdi";
      createPaymentMethodStripe(stripe, card, priceId);
    } else {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: card
        }
      });
      if (payload.error) {
        alert(`Payment failed ${payload.error.message}`);
      } else {
        buyConfirm();
      }
    }
  }
  function createPaymentMethodStripe(stripe : any, cardElement : any, /*customerId : any,*/ priceId : any) {
    
    if (stripe)
    stripe
    .createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    .then((result : any) => {
      if (result.error) {
        console.log(result.error);
        // showToast(result.error.message);
      } else {
        createSubscriptionStripe(
           result.paymentMethod.id,
           priceId
        );
      }
    });
  // });
}


function createSubscriptionStripe(  paymentMethodId : any, priceId :any  ) {
  return (
    fetch(host + '/createSubscriptionStripe' , {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        // customerId: customerId,
        token: userDetails.login.token,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        // reSubscribe: resubscribe
        eventId: eventId
      }),
    })
      .then((response) => {
        return response.json();
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the addional details we need.
      .then((result) => {
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      // .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      // .then(handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      // .then(onSubscriptionComplete)
      .then((res)=>{
        if (res.subscription.status == "OK") {
          // alert("You're not a PREMIUM user!");
          // document.location.href = "/Events";
          done();
          
        }
      })
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        // showCardError(error);
        console.log(error);
        // showToast(error.error.message);
      })
    );
  }
  
  const buyConfirm = () => {
    fetch(host + '/buyStripeConfirm', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: userDetails.login.token,
        tickets: howManyTickets,
        eventId: eventId
      })
    })
    .then(res => {
      return res.json();
    })
    .then(data => {
      done(howManyTickets);
    });
  }

    return (
        <div style={{textAlign: "center", padding: "16px"}}>
          <div id="stripeForm" className="">
          {paytype == "eventFee"? (<span>
            {/* <div style={{textAlign: "center"}}> */}
              <div style={{fontSize: "20pt"}}>Payment for event fee</div>
              <div style={{paddingTop: 12, color: "gray"}}>$5 per 30 days</div>
            {/* </div> */}
            </span>
          ):("")}

          {paytype == "coupon"? (<span>
            {/* <div style={{textAlign: "center"}}> */}
              <div style={{fontSize: "20pt"}}>10% discount coupon</div>
              <div style={{paddingTop: 12, color: "gray"}}>
              {price > 3?(
                <span>The cost of the coupon is <span style={{color: "dodgerblue"}}>${price}</span>, which is 5% of the ticket price.<br/>
                At the event you'll get the ticket for 15% off the full price.</span>
              ): (
                <span>The cost of the coupon is <span style={{color: "dodgerblue"}}>$3</span>, which will be deducted from the ticket.</span>
              )}<br/>
              You can <span style={{color: "dodgerblue"}}>cancel and get a full refund</span> until 24h before the event.
              
                </div>
            {/* </div> */}
            </span>
          ):("")}

          {paytype == "ticket"? (<span>
            {/* <div style={{textAlign: "center"}}> */}
              <div style={{fontSize: "20pt"}}>Payment for discounted ticket</div>
              <div style={{paddingTop: 12, color: "gray"}}>
              <div style={{paddingRight: 40, display: "inline"}}>1 ticket <IonToggle onIonChange={(e:any) => {setClientSecret(""); var howMany = e.detail.checked? 2 : 1; setHowManyTickets(howMany); setPayIntent(howMany);}} color="medium" style={{marginTop: -5, verticalAlign: "middle"}}/> 2 tickets</div>
                <span style={{color: "dodgerblue"}}>${price * howManyTickets}</span></div>
            {/* </div> */}
            </span>
          ):("")}


            <br/>
            
            <div id="card-element" style={{visibility: paytype == "eventFee" || (clientSecret && clientSecret.length > 0)? "visible":"hidden"}}></div>
            
            {/* <Elements stripe={stripePromise}>
              <CardElement id="card-element"></CardElement>
            </Elements> */}
            
            <br/><br/>
            <br/>
            <IonButton size="large" id="payButton" type="button" className="pinkButton" onClick={pay}>Pay</IonButton>
            <br/><br/>
            <div style={{paddingTop: 10, color: "gray"}}>
              Payment ad offers are subject to our <a href="http://www.whotogowith.com/privacy" target="_blank">terms and conditions</a>.
            </div>
          </div>
        </div>
    )
}

export default PaymentsContainer;
