import React, { useState }  from 'react';
import { IonInput, IonLabel, IonSelect, IonSelectOption, 
  IonButton, IonTextarea, IonHeader, IonToolbar, IonIcon, IonDatetime, 
  IonTitle, IonBadge, IonPopover, IonCheckbox, IonItem, useIonViewWillEnter, IonModal, IonContent } from '@ionic/react';
import { useForm } from "react-hook-form";
import { Plugins } from '@capacitor/core';
import {host} from '../App.config.json';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
// import Cropper from 'react-cropper';
// import 'cropperjs/dist/cropper.css'
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import {isIOS} from 'react-device-detect';
// import 'react-image-crop/dist/ReactCrop.css';
import '../theme/ReactCrop.css';
import { useHistory } from 'react-router-dom';

import './MPContainer.css';
import PicturesEditContainer from './PicturesEditContainer';

interface ContainerProps { }

// const cropper = React.createRef();
// var Cropper = require('react-cropper').default;

let userDetails : any;
let eventDetails : any;

const EditEventContainer: React.FC<ContainerProps> = () => {

  const history = useHistory();

  defineCustomElements(window);
  const [eventName, setEventName] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventTypeOther, setEventTypeOther] = useState("");
  const [approved, setApproved] = useState("");
  var dt = new Date();
  dt.setMonth(dt.getMonth() + 1);
  const startDateInit = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
  const maxStartDate = (dt.getFullYear() + 1) + "-12-31";
  const [startDate, setStartDate] = useState(startDateInit);
  const [eventAbout, setEventAbout] = useState("");
  // const [pictures, setPictures] = useState([]);
  const [ticketURL, setTicketURL] = useState("");
  const [ticketPrice, setTicketPrice] = useState(0);
  const [eventIsFree, setEventIsFree] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [disableChat, setDisableChat] = useState(false);
  const [silentMatches, setSilentMatches] = useState(false);
  const [moderateUsers, setModerateUsers] = useState(false);
  const [seeMatches, setSeeMatches] = useState(false);
  const [hideStats, setHideStats] = useState(false);
  const [offerTickets, setOfferTickets] = useState(false);
  
  const [editEventId, setEditEventId] = useState(String);

  function handle_setStartDate (val:any) {
    setStartDate(val);
    Storage.set({key: "eventStartDate", value: val});
  }

  function handle_setEventType (val:any) {
    setEventType(val);
    // Storage.set({key: "eventType", value: val});
    if (val != "Other") {
      setEventTypeOther(val);
    } else {
      setEventTypeOther("");
    }
  }

  const [eventId, setEventId] = useState(String);
  const { Storage } = Plugins;

  const [wideScreenMode, setWideScreenMode] = useState(false);
  const [newEventPopover, setNewEventPopover] = useState(false);

  useIonViewWillEnter(() => {

    window.scrollTo({top: 0, behavior: "smooth"});
    
    if (window.innerWidth > 800) {
      setWideScreenMode(true);
    }

    Storage.get({key:"userDetails"}).then(async (data: any) => {
      if (data.value) {
        userDetails = JSON.parse(data.value + "");

        loglevelServerSend(log,{user: userDetails});

        const params = new URLSearchParams(document.location.search);
        
        let _editEventId = await (await Storage.get({key:"editEventId"})).value;
        if (null == _editEventId) return;
        setEditEventId(_editEventId);
        Storage.remove({key:'editEventId'});

        if (_editEventId == "new") {
          
          setNewEventPopover(true);
          

          fetch(host + "/createEvent", {
            method: 'POST', headers: {
              'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: _editEventId})})
              .then(res => res.json())
              .then(
                (result) => {
                  var event = result.eventDetails;
                  if (event) {
                    eventDetails = event;
                    setEventId(event.id);
                    userDetails = result.userDetails;
                    const userDetailsStringified = JSON.stringify(result.userDetails);
                    Storage.set({key:"userDetails", value: userDetailsStringified});
                    setEventName("");
                    setEventLocation("");
                    setStartDate("");
                    setEventAbout("");
                    setEventType("");
                    setEventTypeOther("");
                    setApproved("");
                    setTicketURL("");
                    setTicketPrice(0);
                    setEventIsFree(false);
                    setRecurring(false);
                    setOfferTickets(false);
                    setDisableChat(false);
                    setSilentMatches(false);
                    setModerateUsers(false);
                    setSeeMatches(false);
                    setHideStats(false);
                  }
                },
                (error) => {
                  log.error("error createEvent (" + _editEventId + "): " + error);
                }
              ); // -fetch
        } else {
          setEventId(_editEventId + "");

          fetch(host + "/getEvent", {
            method: 'POST', headers: {
              'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: _editEventId})})
              .then(res => res.json())
              .then(
                (result) => {
                  var event = result.eventDetails;
                  if (event) {
                    eventDetails = event;
                    setEventName(event.eventName);
                    setEventLocation(event.location);
                    setStartDate(event.start);
                    setEventAbout(event.about);
                    setEventType(event.eventType);
                    setEventTypeOther(event.eventTypeStr);
                    setApproved(event.approved);
                    setTicketURL(event.ticketURL);
                    setTicketPrice(event.ticketPrice);
                    setEventIsFree(event.eventIsFree);
                    setRecurring(event.recurring);
                    setOfferTickets(event.offerTickets);
                    setDisableChat(event.disableChat);
                    setSilentMatches(event.silentMatches);
                    setModerateUsers(event.moderateUsers);
                    setSeeMatches(event.seeMatches);
                    setHideStats(event.hideStats);
                  }
                },
                (error) => {
                  log.error("error getEvent (" + _editEventId + "): " + error);
                }
              ); // -fetch
        }
      }
    });
  });


  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data : any) => {
    
    eventDetails.eventName = eventName;
    eventDetails.location = eventLocation;
    eventDetails.about = eventAbout;
    // eventDetails.start = (await Storage.get({key:'eventStartDate'})).value; // used this because there was a problem in the past with this field
    var nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 2);
    nextDay.setHours(0, 0, 0, 0);
    if (!startDate) {
      alert("Please select a date for the event");
      return;
    }
    if (new Date(startDate) < nextDay) {
      alert("Event start can't be less than 2 days from today");
      return;
    }
    eventDetails.start = startDate;
    if (eventType == "" || eventType == "undefined") {
      alert("Please choose an event type");
      return;
    } else if (eventType == "Other" && eventTypeOther.length < 3) {
      alert("Please specify the event type");
      return;
    }
    eventDetails.eventType = eventType;
    eventDetails.eventTypeStr = eventTypeOther;
    eventDetails.ticketURL = ticketURL;
    eventDetails.ticketPrice = ticketPrice;
    eventDetails.eventIsFree = true;// eventIsFree;
    eventDetails.recurring = recurring;

    eventDetails.disableChat = disableChat;
    eventDetails.silentMatches = silentMatches;
    eventDetails.moderateUsers = moderateUsers;
    eventDetails.seeMatches = seeMatches;
    eventDetails.hideStats = hideStats;
    // if (!eventIsFree && (!ticketPrice || ticketPrice < 1)) {
    //   alert("If the event is not free, ticket price must be filled");
    //   return;
    // }
    eventDetails.offerTickets = offerTickets;
    
    fetch(host + "/setupEvent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({event: eventDetails, token: userDetails.login.token})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
              showStatus("Update successful.");
              history.push("/Events");
            } else {
            showStatus("Update failed.");
            }
          },
          (error) => {
            log.error("error setupEvent (" + eventDetails.eventName + "): " + error);
            showStatus("Update failed.");
          }
        );
        // -fetch
    
  

    

  }; // onSubmit
  
  function showStatus (txt : string) {
    var statusLabel = document.getElementById("statusLabel");
    if (statusLabel) {
      statusLabel.classList.remove("displayNone");
      statusLabel.innerText = txt;
    }
  }

  const savePhotos = (photos: []) => {
    eventDetails.pictures = photos;
    
    fetch(host + "/setupEvent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({event: eventDetails, token: userDetails.login.token})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
            //   showStatus("Update successful.");
              // document.location.href = "/Profile";
            } else {
            // showStatus("Update failed.");
            }
          },
          (error) => {
            log.error("error setupEvent (" + eventDetails.eventName + "): " + error);
            // showStatus("Update failed.");
          }
        );
        // -fetch
  }

  const cancelDeleteEvent = () => {
    let sure = window.confirm("Are you sure?");
    if (!sure) return;
    fetch(host + "/deleteEvent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({eventId: eventId, token: userDetails.login.token})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
            //   showStatus("Update successful.");
              // document.location.href = "/Events";
              history.push("/Events");
            } else {
            // showStatus("Update failed.");
            }
          },
          (error) => {
            log.error("error deleteEvent (" + eventId + "): " + error);
            // showStatus("Update failed.");
          }
        );
        // -fetch
  
  }

  const getUserDetails = () => {
    return userDetails;
  }
  
  return (
    <div className="mpcontainer">
    <form onSubmit={handleSubmit(onSubmit)} id="mainform">
     <IonHeader>
        <IonToolbar>
            {/* <IonButton href="/Profile" disabled>
              <IonIcon className="topicon" src="assets/icon/profile-on.svg" />
            </IonButton>
            <IonButton disabled>
            <IonIcon className="topicon" src="assets/icon/events.svg" />
            </IonButton>
            <IonButton href="/Home" disabled>
              <IonIcon slot="icon-only" src="assets/icon/swipe.svg" />
            </IonButton>
            <IonButton disabled>
              <IonIcon slot="icon-only" src="assets/icon/chat.svg" />
            </IonButton> */}
            <IonButton type="submit" 
            // onClick={()=>{let mainform = document.getElementById("mainform") as HTMLFormElement;if (mainform)mainform.submit();}} 
            slot="start">
              <IonIcon className="" src="assets/icon/left-arrow.svg" />
            </IonButton>
            <IonTitle slot="start">Edit Event</IonTitle>
        </IonToolbar>
    </IonHeader>

{eventId != null && eventId.length > 1 && eventId != "new"? (
    <PicturesEditContainer save={savePhotos} eventId={eventId} aspect={10/7} maxPhotos={1} getUserDetails={getUserDetails} />
  ):""}
      <div className="setupLabel">
      <IonLabel color="medium">Event Name: </IonLabel>
      <IonInput name="eventName" placeholder="" maxlength={50} ref={register({ required: true })} onBlur={(e:any)=>{setEventName(e.target.value + ""); return false;}} value={eventName}></IonInput>
      <IonLabel color="warning">{errors.eventName && (errors.eventName.message || "Event Name is required")}</IonLabel>
      <br/>
      <IonLabel color="medium">Event Location</IonLabel>
      <IonInput name="eventLocation" placeholder="Location" maxlength={60} ref={register()} onBlur={(e:any)=>{setEventLocation(e.target.value + ""); return false;}} value={eventLocation}></IonInput>
      <br/>
      
      <div style={{display: "inline-flex", marginTop: 12}}>Event Start Date</div>
      <IonButton id="open-modal" color='warning' className="zpinkButton">{startDate? startDate.substring(0,10) : "-"}</IonButton>
      <IonModal trigger="open-modal">
        <IonContent>
          <IonDatetime id="startDate" max={maxStartDate} name="startDate" value={startDate} onIonChange={(e:any)=>{handle_setStartDate(e.detail.value!)}} presentation="date" showDefaultButtons={true}></IonDatetime>
        </IonContent>
      </IonModal>

      <br/><br/>
      <IonLabel color="medium">Type</IonLabel>
      <IonSelect name="eventType" value={eventType} placeholder="select..." interface="popover" onIonChange={(e:any) => {handle_setEventType((e.target as HTMLIonSelectElement).value + "")}}>
        <IonSelectOption value="Class">Class</IonSelectOption>
        <IonSelectOption value="Festival">Festival</IonSelectOption>
        <IonSelectOption value="House Party">House Party</IonSelectOption>
        <IonSelectOption value="Meetup">Meetup</IonSelectOption>
        <IonSelectOption value="Party">Party</IonSelectOption>
        <IonSelectOption value="Show">Show</IonSelectOption>
        <IonSelectOption value="Trip">Trip</IonSelectOption>
        <IonSelectOption value="Wedding">Wedding</IonSelectOption>
        <IonSelectOption value="Workshop">Workshop</IonSelectOption>
        <IonSelectOption value="Other">Other</IonSelectOption>
      </IonSelect>
      <IonLabel color="warning">{errors.eventType && "Event Type is required"}</IonLabel>
      <IonInput hidden={["Other"].indexOf(eventType) == -1} name="eventTypeOther" placeholder="Event Type" maxlength={60} ref={register()} onBlur={(e:any)=>{setEventTypeOther(e.target.value + ""); return false;}} value={eventTypeOther}></IonInput>
      <br/>
      <IonLabel color="medium">About</IonLabel>
      <IonTextarea rows={5} name="about" value={eventAbout} placeholder="" maxlength={500} ref={register()} onBlur={(e:any)=>{setEventAbout(e.target.value + ""); return false;}}></IonTextarea>
      <br/>

      <IonItem>
      <IonCheckbox name="recurring" checked={recurring} onIonChange={(e:any) => setRecurring(e.detail.checked)}></IonCheckbox>
      <IonLabel>Recurring event</IonLabel>
      </IonItem>

      <div className={"paddingSides smallerFont" + (eventIsFree? " disabledText":"")}>
        When a recurring event start date passes, the payment subscription doesn't cancel. 
        The organizer still needs to manually set the start date to the next.
      </div>

      <br/>

      <IonItem>
      <IonCheckbox name="disableChat" checked={disableChat} onIonChange={(e:any) => setDisableChat(e.detail.checked)}></IonCheckbox>
      <IonLabel>Disable chat</IonLabel>
      </IonItem>

      <div className={"paddingSides smallerFont"}>
      Users will not be able to chat with matches
      </div>
      <br/>

      <IonItem>
      <IonCheckbox name="silentMatches" disabled={!seeMatches} checked={silentMatches} onIonChange={(e:any) => setSilentMatches(e.detail.checked)}></IonCheckbox>
      <IonLabel>Silent matches</IonLabel>
      </IonItem>

      <div className={"paddingSides smallerFont"}>
      Users will NOT know if they matched when swiping (you must check "Organizer can see matches" to enabled this)
      </div>
      <br/>

      <IonItem>
      <IonCheckbox name="moderateUsers" checked={moderateUsers} onIonChange={(e:any) => setModerateUsers(e.detail.checked)}></IonCheckbox>
      <IonLabel>Moderate users</IonLabel>
      </IonItem>

      <div className={"paddingSides smallerFont"}>
        You will need to approve each user before they can start swiping on profiles
      </div>
      <br/>

      <IonItem>
      <IonCheckbox name="seeMatches" checked={seeMatches} onIonChange={(e:any) => {setSeeMatches(e.detail.checked); if (false == e.detail.checked) setSilentMatches(false)}} disabled={approved == "yes"}></IonCheckbox>
      <IonLabel>Organizer can see matches</IonLabel>
      </IonItem>

      <div className={"paddingSides smallerFont"}>
        You the organizer will see who liked and matched with who.
        <br/>
        Users will be informed that you're turned on this feature.
        <br/>
        You can not change this after going live.
      </div>

    <IonItem>
    <IonCheckbox name="hideStats" checked={hideStats} onIonChange={(e:any) => setHideStats(e.detail.checked)}></IonCheckbox>
    <IonLabel>Hide the number of men/women going to this event</IonLabel>
    </IonItem>

      <div style={{display: "none"}}>

      <IonItem>
      <IonCheckbox name="eventIsFree" checked={true || eventIsFree} onIonChange={(e:any) => setEventIsFree(e.detail.checked)}></IonCheckbox>
      <IonLabel>The event is free of charge</IonLabel>
      </IonItem>


      <IonItem>
      <IonLabel color="medium">Ticket price (full): &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonLabel>
      <IonInput disabled={eventIsFree} name="ticketPrice" type="number" onBlur={(e:any)=>{setTicketPrice(Number.parseInt(e.target.value + "")); return false;}} value={ticketPrice}></IonInput>
      <IonLabel color="medium">$ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonLabel>
      </IonItem>


      <br/><br/>
      <div hidden={true} className={"paddingSides smallerFont" + (eventIsFree? " disabledText":"")}>
      Coupons for 10% discount will be sold for price of 5% of the ticket.
      <br/><br/>
      The coupons should be respected at the event and traded for tickets at 15% price (10% discount, the 5% paid by the guest is WhoToGoWith's fee). 
      <br/><br/>
      Coupons can be cancelled and and refunded anytime up to 24 hours before the event start.
      <br/></div>

      <IonItem hidden={true}>
      <IonCheckbox name="offerCoupons" checked={true} disabled={true}></IonCheckbox>
      <IonLabel>Offer 10% discount coupons</IonLabel>
      </IonItem>
      {/* <br/><br/> */}
      <div className={"paddingSides smallerFont" + (eventIsFree? " disabledText":"")}>
      Tickets will be sold at 15% discount to couples who match and decide to go together. 
      The money paid will then be transfered to the organizer, minus 10% fee (from the ticket full price).
      </div><br/>



      <IonItem>
      <IonCheckbox disabled={true} name="eventIsFree" checked={true} onIonChange={(e:any) => setOfferTickets(e.detail.checked)}></IonCheckbox>
      <IonLabel>Offer Tickets at 15% discount</IonLabel>
      </IonItem>

      <br/><br/>
      <div className={"paddingSides smallerFont" + (eventIsFree || !offerTickets? " disabledText":"")}>
      <b>Tickets purchase confirmation URL (optional)</b> - You may enter a URL to forward users after they purchase a ticket.
      </div>

      <IonItem>
      <IonInput disabled={eventIsFree || !offerTickets} name="ticketURL" placeholder="http://" type="url" maxlength={1250} onBlur={(e:any)=>{setTicketURL(e.target.value + ""); return false;}} value={ticketURL}></IonInput>
      </IonItem>

      </div>

        <br/>

      </div>

      <IonPopover
        isOpen={newEventPopover}
        className={(wideScreenMode? 'createEventInfoPopup createEventInfoPopupWide' : 'createEventInfoPopup') + " "}
        onDidDismiss={() => setNewEventPopover(false)} 
        >
          <div className={"genericPopoverInner" + (isIOS? "Ios":"") + " createEventInfoPopupInner"}>
          <div className="centered">
            {/* One Time | Recurring
            <br/><br/> */}
            
            {/* <b>Event</b>
            <br/><br/> */}
            Create an event on WhoToGoWith
            <br/><br/>
            <b>$5 / Month</b>
            </div>
            <br/><br/>
            Connect singles who want to attend your event
            <br/><br/>
            Get insights about matches and interactions
            <br/><br/>
            Unlimited number of users
            <br/><br/>
            For any question - WTGW support team is on the chat screen,
            <br/>
             or thru email: info@whotogowith.com
            <br/><br/>
            <br/><br/>
            <div className="centered">
            <IonButton size="large" id="startButton" onClick={()=>{setNewEventPopover(false);}} type="button" className="pinkButton">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Start &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonButton>
            </div>

          </div>

        </IonPopover>

     
      {editEventId == "new"? "" :(
      <span><br/><br/>
      <IonBadge slot="end" color="success" hidden={approved != "yes"}>
        Event approved
      </IonBadge>
      <IonBadge slot="end" color="danger" hidden={approved != "no"}>
        Event not approved
      </IonBadge>
      <IonBadge slot="end" color="warning" hidden={approved != "pendingpay"}>
        Pending payment (pay from events screen)
      </IonBadge>
      <IonBadge slot="end" color="warning" hidden={approved != "pending"}>
        Pending approval
      </IonBadge>
      </span>
      )}

      <br/><br/>
      <IonButton size="large" id="saveButton" type="submit" className="pinkButton">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Save &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonButton>
      {editEventId != "new"? (
      <IonButton size="large" id="cancelButton" onClick={()=>{history.push("/Events");}}  className="" color="medium" type="button" >Cancel</IonButton>
      ):""}
      <br/><br/><br/><br/>
      <IonLabel id="statusLabel" color="primary" className="displayNone">---</IonLabel><br/>
      <IonButton size="large" id="cancelDeleteButton" fill={editEventId == "new"? "outline" : "clear"} onClick={()=>{cancelDeleteEvent();}} color="medium">{editEventId == "new"? "Cancel" : "Delete Event"}</IonButton>
      
      {/* <IonButton size="large" id="saveButton" className="pinkButton" href="Home" type="submit" >Save</IonButton> */}
      <br></br><br></br>
        {/* {(hasSubscription)? ( */}
      {/* <IonButton id="cancelSubButton" color="warning" fill="clear" href="/subscription">Subscription</IonButton> */}
      {/* ):""} */}
      <br/><br/>
      </form>
    </div>
    
  );
};


export default EditEventContainer;
