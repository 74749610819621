import React, { useState } from 'react';
import { IonLabel, IonFooter, IonButton, IonPopover, IonBadge, useIonViewWillEnter, IonicSlides } from '@ionic/react';
import './MPContainer.css';
import { Plugins } from '@capacitor/core';
import {host} from '../App.config.json';
import {swipeSlider} from './utils';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import {isIOS} from 'react-device-detect';
import ReportContainer from './ReportContainer';
import { useHistory } from 'react-router';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Zoom } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/modules/keyboard/keyboard.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import 'swiper/modules/zoom/zoom.min.css';
import '@ionic/react/css/ionic-swiper.css';

interface ContainerProps { }

let userDetails : any;
let eventId : string;

let profile : any;
let matchedProfile : any;
let _eventOrganizerUserId : string;


let slider : any;
let pictureElement: any;

const SwipeContainer: React.FC<ContainerProps> = () => {

  const history = useHistory();

  const { Storage } = Plugins;

  const [eventName, setEventName] = useState("");
  const [eventStartDays, setEventStartDays] = useState<number>(0);
  const [eventMen, setEventMen] = useState<number>(0);
  const [eventWomen, setEventWomen] = useState<number>(0);
  const [eventYoung, setEventYoung] = useState<number>(0);
  const [eventOld, setEventOld] = useState<number>(0);
  const [eventOrganizerUserId, setEventOrganizerUserId] = useState("");
  const [organizerSwipedAlready, setOrganizerSwipedAlready] = useState<number>(0);
  const [organizerGoing, setOrganizerGoing] = useState<number>(0);
  const [eventModerateUsers, setEventModerateUsers] = useState(false);

  const [userName, setUserName] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userAbout, setUserAbout] = useState("");
  const [pictures, setPictures] = useState([]);
  const [nextPictures, setNextPictures] = useState([]);
  const [nextProfileId, setNextProfileId] = useState("");
  const [approved, setApproved] = useState(false);

  const [swipeButtonsDisabled, setSwipeButtonsDisabled] = useState(false);
  
  const [wideScreenMode, setWideScreenMode] = useState(false);

  useIonViewWillEnter(async () => {

    if (window.innerWidth > 800) {
      setWideScreenMode(true);
    }

    let userDetailsStr = await Storage.get({key:"userDetails"});
    userDetails = JSON.parse(userDetailsStr.value + "");

    loglevelServerSend(log,{user: userDetails});

    if (!userDetails.pictures || userDetails.pictures.length == 0) {
      alert("Please add at least 1 photo before starting");
      history.push("/Setup");
    }
    eventId = await (await Storage.get({key:"swipingForEventId"})).value + "";
    
    if (eventId == "null") {
      alert("To start swiping, select an event.");
      history.push("/Events");
    }
    fetch(host + "/getEvent", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: eventId})})
        .then(res => res.json())
        .then(
          (result) => {
            var event = result.eventDetails;
            
            if (event) {
              setEventName(event.eventName);
              let diff = Math.abs(new Date(event.start).getTime() - new Date().getTime());
              let diffDays = Math.ceil(diff / (1000 * 3600 * 24)) - 1; 
              setEventStartDays(diffDays);
              setEventMen(result.stats.men? result.stats.men : 0);
              setEventWomen(result.stats.women? result.stats.women : 0);
              setEventOld(result.stats.oldest);
              setEventYoung(result.stats.youngest);
              setEventOrganizerUserId(event.organizerUserId);
              _eventOrganizerUserId = event.organizerUserId;
              if (event.moderateUsers && event.organizerUserId == userDetails.id) setEventModerateUsers(event.moderateUsers);
              loadNextProfile();
            }
          },
          (error) => {
            log.error("error getEvent (" + eventId + "): " + error);
          }
        ); // -fetch
  });

  const [filtered, setFiltered] = useState<number>(0);

  function loadNextProfile (rewind?: Boolean) {
    fetch(host + "/loadNextProfile", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: eventId, rewind: rewind && canRewind, isEventHost: _eventOrganizerUserId == userDetails.id})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
              setSwipeButtonsDisabled(false);
              profile = result.profile;
              setPictures([]);
              setPictures(result.profile.pictures);
              setNextPictures([]);
              setNextPictures(result.nextPictures != null? result.nextPictures : []);
              setNextProfileId(result.nextProfileId);
              setUserName(result.profile.userName);
              setUserAge(result.profile.age);
              setApproved(true == result.approved);
              if (result.profile.city && result.profile.city.length > 1)
              setUserCity("Lives in " + result.profile.city);
              setUserAbout(result.profile.about);
              setNoProfilesToShow(false);
              if (_eventOrganizerUserId == userDetails.id) {
                setOrganizerGoing(result.going);
                setOrganizerSwipedAlready(result.swipedAlready);
              }
            } else {
              profile = null;
              setPictures([]);
              setNextPictures([]);
              setNextProfileId("");
              setUserAbout("");
              setUserAge("");
              setUserName("");
              setUserCity("");
              setNoProfilesToShow(true);
              setCanStartFromScratch(result.canStartFromScratch);
              setFiltered(result.filtered);
            }
          },
          (error) => {
            log.error("error loadNextProfile (" + eventId + ", rewind: " + (rewind && canRewind) + "): " + error);
          }
        ); // -fetch
  }
  
  const [noProfilesToShow, setNoProfilesToShow] = useState(false);
  const [canStartFromScratch, setCanStartFromScratch] = useState(true);
  const [canRewind, setCanRewind] = useState(true); // not implemented yet

  const moderateUser = (approved: Boolean) => {
    if (swipeButtonsDisabled) return;
    setSwipeButtonsDisabled(true);
    fetch(host + "/moderateUser", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: eventId,
        profileId: profile.id, approved: approved})})
        .then(res => res.json())
        .then(
          (result) => {
            loadNextProfile();
          },
          (error) => {
            log.error("error moderateUser (eventId: " + eventId + ", profileId: " + profile.id + ", liked: " + approved + "): " + error);
          }
        ); // -fetch
  }

  const swipeProfile = (liked: Boolean) => {
    if (swipeButtonsDisabled) return;
    setSwipeButtonsDisabled(true);
    fetch(host + "/swipeProfile", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: eventId,
        profileId: profile.id, liked: liked})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "matched") {
              matchedProfile = result.profile;
              setShowMatchedPopover(true);
            } else {
              loadNextProfile();
            }
          },
          (error) => {
            log.error("error swipeProfile (eventId: " + eventId + ", profileId: " + profile.id + ", liked: " + liked + "): " + error);
          }
        ); // -fetch
  }

  const [showMatchedPopover, setShowMatchedPopover] = useState(false);

  const sendMessage = () => {
    setShowMatchedPopover(false);
    Storage.set({key:"toUserId", value: matchedProfile.id});
    history.push("/Chat");
  }

  const startFromScratch = () => {
    fetch(host + "/startFromScratch", {
      method: 'POST', headers: {
        'Content-Type': 'application/json'}, body: JSON.stringify({token: userDetails.login.token, eventId: eventId, wipeLikes: eventModerateUsers})})
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status == "success") {
              loadNextProfile();
            }
          },
          (error) => {
            log.error("error startFromScratch (" + eventId + "): " + error);
          }
        ); // -fetch
  }

  let pictureIdx = 0; // scroll through pictures in wide screen mode

  return (
    <div className={"mpcontainer maincontainer" + (isIOS && false? " iosPaddingTop" : "")}>
      <IonPopover
        isOpen={showMatchedPopover}
        className={wideScreenMode? 'matchedPopover matchedPopoverWide' : 'matchedPopover'}
        onDidDismiss={() => {setShowMatchedPopover(false);loadNextProfile();}}
        >
          <div className="matchedPopoverDiv">
            <h2 className="pinkLabel">YESSSSS!</h2>
            <b className="capitalize">{matchedProfile? matchedProfile.userName : ""}</b>
            <br/>
             WANTS TO GO WITH YOU TO
             <br/> <b>{eventName}</b>!
            <br/><br/>
            {userDetails && matchedProfile? (
            <div>
            <img src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + userDetails.id + "_" + userDetails.pictures[0] + ".jpeg"} className="matchedPicture"/> &nbsp;
            <img src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + matchedProfile.id + "_" + matchedProfile.pictures[0] + ".jpeg"} className="matchedPicture"/>
            </div>
            ):("")}
            <br/><br/>
            <IonButton size="large" id="sendMessageButton" type="button" onClick={()=>{sendMessage(); }} className="pinkButton matchedActionButtons">SEND MESSAGE</IonButton>
            <br/>
            <IonButton size="large" id="keepSwipingButton" type="button" onClick={()=>{setShowMatchedPopover(false); }} className="greyButton matchedActionButtons">KEEP SWIPING</IonButton>  
            <br/><br/>
          </div>
        </IonPopover>
      <div className={wideScreenMode? "swipingFor swipingForWide" : "swipingFor" + (isIOS? " swipingForIos":"")}>
      <div className="paddingBottom">
        <IonBadge slot="end" color="primary"><img src="assets/icon/event.svg" className="verticalMiddle" /> {eventName}</IonBadge>
        </div><div className="paddingBottom">
        <IonBadge slot="end" color="medium"><img src="assets/icon/time.svg" className="verticalMiddle" /> {eventStartDays > 2? "In " + eventStartDays + " days" : eventStartDays == 1? "Tomorrow" : eventStartDays == 0? "Today" : ""}</IonBadge>
        </div>
        <div className="paddingBottom" hidden={eventMen == 0 && eventWomen == 0}>
        <IonBadge slot="end" color="medium"><img src="assets/icon/men.svg" className="verticalMiddle" /> {eventMen}</IonBadge>
         &nbsp; 
        <IonBadge slot="end" color="medium"><img src="assets/icon/women.svg" className="verticalMiddle" /> {eventWomen}</IonBadge>
        </div>
        <div className="paddingBottom" hidden={eventYoung == null || eventOld == null}>
        <IonBadge slot="end" color="medium"><img src="assets/icon/ages.svg" className="verticalMiddle" /> {eventYoung}-{eventOld}</IonBadge>
        </div>
        {organizerGoing > 0? (
          <div className="paddingBottom">
        <IonBadge slot="end" color="medium">#{organizerSwipedAlready + 1} of {organizerGoing}</IonBadge>
        </div>
        ):("")}
      </div>
      {nextPictures.length > 0? (
        <div style={{height: 1, width: 1, opacity: 0.1}}>
          {nextPictures.map(nextPicture =>
              <img src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + nextProfileId + "_" + nextPicture + ".jpeg"} className="" style={{height: 1, width: 1, opacity: 0.1}}/>
            )}
        </div>
      ):""}
      {wideScreenMode? (
        <div>
        <br/><br/>
        {pictures.length > 0? (
          <span>
            <IonButton className="swipeProfilePictureLeft" color="danger" onClick={()=>{
              if (pictureElement == undefined) return;
          if (pictureIdx > 0) {
            pictureIdx--;
          } else {
            pictureIdx = pictures.length - 1;
          }
          (pictureElement as HTMLImageElement).src = "https://gowith.s3.us-east-2.amazonaws.com/users/" + profile.id + "_" + pictures[pictureIdx] + ".jpeg";
          }}>&lt;</IonButton>
        <img onMouseOver={(e:any)=>{pictureElement = e.target;}}
         src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + profile.id + "_" + pictures[pictureIdx] + ".jpeg"} 
         onClick={(e:any)=>{
           let imgRef = (e.target as HTMLImageElement);
           if (imgRef.className.indexOf('profilePhotoWideBigHover') > -1) {
            imgRef.className = 'profilePhotoWideBig cursorHand';
           } else {
            imgRef.className = 'profilePhotoWideBigHover cursorHand';
           }
          }}
        //  onMouseOut={(e:any)=>{(e.target as HTMLImageElement).className = 'profilePhotoWideBig cursorHand';}}
          className="profilePhotoWideBig cursorHand"/>
          <IonButton className="swipeProfilePictureRight" color="danger" onClick={()=>{
            if (pictureElement == undefined) return;
          if (pictures.length > (pictureIdx + 1)) {
            pictureIdx++;
          } else {
            pictureIdx = 0;
          }
          (pictureElement as HTMLImageElement).src = "https://gowith.s3.us-east-2.amazonaws.com/users/" + profile.id + "_" + pictures[pictureIdx] + ".jpeg";
          }}>&gt;</IonButton>
          </span>
      ):""}
      {pictures.length == 0? (
       <img src={"assets/icon/defaultuserphoto.svg"} className="profilePhotoWide"/>
      ):("")}
      </div>
      ) : pictures && pictures.length > 0? (
       <Swiper modules={[Pagination, Zoom, IonicSlides]} zoom={true} pagination={true} onClick={(e:any)=>{if (wideScreenMode) swipeSlider(e, slider);}}>
        {pictures.map(picture =>
        <SwiperSlide>
          <img src={"https://gowith.s3.us-east-2.amazonaws.com/users/" + profile.id + "_" + picture + ".jpeg"} className="profilePhoto"/>
        </SwiperSlide>
        )}
        {pictures.length == 0? (
        <SwiperSlide>
         <img src={"assets/icon/defaultuserphoto.svg"} className="profilePhoto"/>
        </SwiperSlide>
        ):("")}
      </Swiper>
      ): pictures && pictures.length == 0 && userName && !noProfilesToShow? (
         <img src={"assets/icon/defaultuserphoto.svg"} className="profilePhoto"/>
        ):("")}
        
      <br/><br/>
      <div className="profileDetails" hidden={noProfilesToShow}>
      <IonLabel className="profileName"><b>{userName? userName + "," : ""}</b> {userAge}</IonLabel><br/>
      <IonLabel className="profileCity">{userCity}</IonLabel>
        
        <br/><br/>
        <div className={"profileBio" + (/[א-ת].+/.test(userAbout)? " rtl":"")}>
        <span className="preLine">{userAbout}</span><br/><br/>
        {profile? (
        <ReportContainer text="Report and block user" type="user" id={profile.id}></ReportContainer>
        ):""}
        </div>
        <br/><br/><br/>
        <br/><br/><br/>
        {/* lots of newlines in the end to cover for the footer */}
      </div>
      <div className="centered">{noProfilesToShow? (
          <div style={{paddingTop: wideScreenMode? 0 : 200}}>
            <span className="emptyScreenLabel">
            
            <br/><br/>
            {_eventOrganizerUserId == userDetails.id? (
              <span>No one has joined yet</span>
            ):(
            <span>We've run out of potential matches for this event.
              {wideScreenMode? (<br/>):""}
              Come back later when more invitees join the platform.</span>
            )}
            <br/><br/>
            {/* {filtered > 0? (
              <div>
                {filtered} more profiles to show if you change your age/gender preferences.
                <br/><br/>
              </div>
            ):("")} */}
            
            </span>
            <br/><br/><br/>
            {canStartFromScratch? (
            <IonButton size="large" id="startFromScratch" className="pinkButton" onClick={()=>{startFromScratch();}}>Start from scratch?</IonButton>
            ):("")}
            </div>
          ):("")}</div>
      {!userName || noProfilesToShow? (""):(
      <IonFooter className={wideScreenMode? "myfooter myfooterWide" : "myfooter" + (isIOS? " myFooterIos":"")}>
        <img src={"assets/icon/rewind" + (wideScreenMode? "-web":"") + ".svg"} className={"rewindButton" + (wideScreenMode? "Web":"") + " cursorPointer"} onClick={()=>{loadNextProfile(true);}}/>
        {_eventOrganizerUserId == userDetails.id && !eventModerateUsers? (
          <span><img src="assets/icon/next.svg" className="likeButton cursorPointer" onClick={()=>{swipeProfile(false);}}/></span>
        ): eventModerateUsers? (
          <span>
          <img src={"assets/icon/next.svg"} className={"nopeButton" + (wideScreenMode? "-web":"") + " cursorPointer"} onClick={()=>{swipeProfile(false);}}/>
          
            <img src={"assets/icon/nope.svg"} className={"nopeButton" + (wideScreenMode? "-web":"") + " cursorPointer"} onClick={()=>{let sure = window.confirm ("Disapprove?"); if (sure) moderateUser(false);}}/>
           {!approved? (
            <img src={"assets/icon/approve" + (wideScreenMode? "-web":"") + ".svg"} className={"likeButton" + (wideScreenMode? "-web":"") + " cursorPointer"} onClick={()=>{let sure = window.confirm ("Approve?"); if (sure) moderateUser(true);}}/>
            ):""} 
        </span>
        ) : (
        <span>
          <img src={"assets/icon/nope" + (wideScreenMode? "-web":"") + ".svg"} className={"nopeButton" + (wideScreenMode? "-web":"") + " cursorPointer"} onClick={()=>{swipeProfile(false);}}/>
          <img src={"assets/icon/like" + (wideScreenMode? "-web":"") + ".svg"} className={"likeButton" + (wideScreenMode? "-web":"") + " cursorPointer"} onClick={()=>{swipeProfile(true);}}/>
        </span>
        )}
      </IonFooter>
      )}
    </div>
  );
};

export default SwipeContainer;
