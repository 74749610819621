import { IonButton, IonCol, IonGrid, IonItem, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import React  from 'react';
import {isIOS, isAndroid, isMobileSafari, isChrome, isChromium, isEdge, isEdgeChromium, isFirefox, isOpera, isSafari} from 'react-device-detect';
import './MPContainer.css';

interface ContainerProps { }

const MobileWebFooterContainer: React.FC<ContainerProps> = () => {

  return (
    <div className="footerContainer">
        {/* <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/> */}
    <div className="WebFooter">
    <div style={{backgroundImage: "url(assets/icon/footer-box-h.png)", backgroundRepeat: "repeat-x", height: 16}}>
&nbsp;
            </div>
        <IonGrid className="centered footerGridMobile">
            <IonRow className="footerSecondRow">
                <IonCol className="centered">
                    <div className="appDownloadIcons">
                    <a href="https://apps.apple.com/us/app/who-to-go-with/id1534771055" target="_blank">
                        <img src="assets/icon/applestore.png" className="apppleStoreIcon"/>
                        </a> 
                    {isIOS? ("") : (
                        <a href="https://play.google.com/store/apps/details?id=com.whotogowith" target="_blank"><img src="assets/icon/googleplay.png" className="googlePlayIcon"/></a>
                    )}
                    </div>
                    
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="centered">
                <br/>
                <br/>
                © 2022 WhoToGoWith
                </IonCol>
            </IonRow>
        </IonGrid>

        
    </div>
    </div>
  );
};


export default MobileWebFooterContainer;
