import React, { useState }  from 'react';
import { IonButton, IonToast } from '@ionic/react';
import { IonInput, IonLabel } from '@ionic/react';
import { useForm } from "react-hook-form";
import { Plugins } from '@capacitor/core';
import {host, appleSI} from '../App.config.json';
import FaceBookSignInUpContainer from './FaceBookSignInUpContainer';
import log from 'loglevel';
import {loglevelServerSend} from './utils';
import {isIOS} from 'react-device-detect';
import './MPContainer.css';
import AppleSignInUpContainer from './AppleSignInUpContainer';
import { useHistory } from 'react-router';

interface ContainerProps { }

const SignInContainer: React.FC<ContainerProps> = () => {

  const history = useHistory();
  
  const { Storage } = Plugins;

  Storage.get({key:'userDetails'}).then((data: any) => {
    if (data.value) {
      let userDetails = JSON.parse(data.value);
      loglevelServerSend(log,{user: userDetails});
      setValue("email", userDetails.email);
    } else {
      loglevelServerSend(log,{});
    }
  });
  Storage.get({key:'userAlreadyExists'}).then((data: any) => {
    if (data.value) {
      var userAlreadyExistsLabel = document.getElementById("userAlreadyExistsLabel");
      if (userAlreadyExistsLabel) {
        userAlreadyExistsLabel.className = "statusLabelVisible";
      }
      // setValue("email", data.value);
      setEmail(data.value);
      Storage.remove({key:"userAlreadyExists"});
    } 
  });

  const [email, setEmail] = useState("");
  const [showToast1, setShowToast1] = useState(false);

  const forgotPassword = () => {
    if(email && email.length > 10 && email.indexOf("@") > 2) {
      fetch(host + "/forgotPassword", {
        method: 'POST', headers: {
          'Content-Type': 'application/json'}, body: JSON.stringify({email: email})})
          .then(res => res.json())
          .then(
            (result) => {
              if (result.status == "success") {
                setShowToast1(true);
              }
            },
            (error) => {
              log.error("error forgotPassword (" + email + "): " + error);
            }
          );
          // -fetch
    } else {
      alert("Please type in your email");
    }
  }

  const { register, handleSubmit, errors, setValue } = useForm();
  const onSubmit = (data : any) => {
    
    fetch(host + "/signin", {
    method: 'POST', headers: {
      'Content-Type': 'application/json'}, body: JSON.stringify({email: email.trim(), password: data.password})})
      .then(res => res.json())
      .then(
        (result) => {
          var userAlreadyExistsLabel = document.getElementById("userAlreadyExistsLabel");
          if (userAlreadyExistsLabel) {
            userAlreadyExistsLabel.classList.remove("displayNone");
            if (result.status == "fail") {
              userAlreadyExistsLabel.textContent = "Login failed";
              
            } else if (result.status == "success") {
              userAlreadyExistsLabel.textContent = "Login succeess";
              Storage.set({key:"userDetails", value: JSON.stringify(result.userDetails)});
              history.push("/Events");
            }
          }
        },
        (error) => {
          log.error("error singin (" + email + "): " + error);
        }
      );

  };
  
  const appleCB = (res: any) => {
    alert(res);
  }

  return (
    <div className={"mpcontainer" + (isIOS? " iosPaddingTop" : "")}>
      <br/>
      <img src="assets/icon/logo_002.png" className="logoBig"/>
     <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={"An email with instruction to reset password was sent to " + email}
        duration={6000}
      />
     <form onSubmit={handleSubmit(onSubmit)}><br/>
       <IonLabel id="userAlreadyExistsLabel" color="warning" className="displayNone">User already exists with this email</IonLabel>
     
      {/* <IonLabel>Email</IonLabel> */}
      <IonInput name="email" placeholder="Email or username" value={email} onIonChange={(e:any)=>{setEmail((e.target as HTMLInputElement).value)}} maxlength={50}  />
      <IonLabel color="warning">{errors.email && errors.email.message}</IonLabel>
      <br/>
      {/* <IonLabel>Password</IonLabel> */}
      <IonInput name="password" placeholder="Password" type="password" maxlength={20} ref={register({ required: true })} />
      <IonLabel color="warning">{errors.password && "Required"}</IonLabel>
      <br/>

      <br/>
      <IonButton size="large" id="signInButton" type="submit" className="pinkButton">Sign In</IonButton> 
      <br/><br/>
      <IonButton size="default" fill="clear" id="cancelButton" onClick={()=>{history.push("/SignUp")}} color="medium">Sign Up</IonButton>
      <IonButton onClick={()=>{history.push("/Hello")}} className="pinkLabel" fill="clear">Find event</IonButton>
      
      {/* <br/><br/>
      {!isIOS? (
      <FaceBookSignInUpContainer></FaceBookSignInUpContainer>
      ):""} */}
      <br/><br/>
      {appleSI == "1"? (
      <AppleSignInUpContainer></AppleSignInUpContainer>
      ):""}
      <br></br>
      <IonButton fill="clear" className="pinkLabel" onClick={forgotPassword}>Forgot password?</IonButton>
      </form>
    </div>
    
  );
};


export default SignInContainer;
