import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonToast, IonLabel } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { setupIonicReact } from '@ionic/react';


import SWIPE from './pages/Swipe';
// import IMIN from './pages/ImIN';
import SETUP from './pages/Setup';
import SIGNOUT from './pages/SignOut';
import SIGNUP from './pages/SignUp';
import SIGNIN from './pages/SignIn';
import PROFILE from './pages/Profile';
import EVENTS from './pages/Events';
// import EVENTDETAILS from './pages/EventDetails';
import EDITEVENT from './pages/EditEvent';
import CHATS from './pages/Chats';
import CHAT from './pages/Chat';
import HELLO from './pages/Hello';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import ReactDOM from 'react-dom';
import { Plugins } from '@capacitor/core';
import ProfileContainer from './components/ProfileContainer';

let exitbuttonPressed = 0;
let exitClickInterval : any;
var exitClickSeconds = 2, exitClickSecondsDef = 2;

setupIonicReact({
  mode: 'md'
});

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet animated={false}>
        <Route path="/swipe" component={SWIPE} exact={true} />
        <Route exact path="/" render={() => <Redirect to="/Events" />} />
        {/* <Route path="/imin" component={IMIN} exact={true} /> */}
        <Route path="/setup" component={SETUP} exact={true} />
        <Route path="/profile" component={PROFILE} exact={true} />
        <Route path="/signout" component={SIGNOUT} exact={true} />
        <Route path="/signup" component={SIGNUP} exact={true} />
        <Route path="/signin" component={SIGNIN} exact={true} />
        <Route path="/events" component={EVENTS} exact={true} />
        {/* <Route path="/eventDetails" component={EVENTDETAILS} exact={true} /> */}
        <Route path="/editEvent" component={EDITEVENT} exact={true} />
        <Route path="/chats" component={CHATS} exact={true} />
        <Route path="/chat" component={CHAT} exact={true} />
        <Route path="/hello" component={HELLO} exact={true} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);


document.addEventListener('ionBackButton', (ev) => {
  ev.preventDefault();
  ev.stopImmediatePropagation();
  if (['/subscription'].indexOf(window.location.pathname.toLowerCase()) > -1) {
    window.location.href = '/';
  } else if (['/chat'].indexOf(window.location.pathname.toLowerCase()) > -1 && ['/chats'].indexOf(window.location.pathname) == -1) {
    window.location.href = '/chats';
  } else if (['/setup'].indexOf(window.location.pathname.toLowerCase()) == -1) {
    backPushed();
    const ttt = (
      <IonToast
    isOpen={true}
    message="Tap back again to exit"
    duration={1000}/>
    );
    var exitDiv = document.getElementById('exitDiv');
    ReactDOM.render(<IonLabel/>, exitDiv);
    ReactDOM.render(ttt, exitDiv);
  } 
});

const backPushed = () => {
  var now = new Date();
    if (exitbuttonPressed == 0) {
      exitbuttonPressed = Math.round(now.getTime() / 1000);
      clearInterval(exitClickInterval);
      exitClickInterval = setInterval(() => {
        if (exitClickSeconds > 0) {
        } else {
          exitClickSeconds = exitClickSecondsDef;
          exitbuttonPressed = 0;
          clearInterval(exitClickInterval);
        }
      }, 1000);
    } else if (exitbuttonPressed > (Math.round(now.getTime() / 1000)) - exitClickSecondsDef) {
      Plugins.App.exitApp();
    } else {
      exitbuttonPressed = 0;
      exitClickSeconds = exitClickSecondsDef;
    }
}

export default App;

